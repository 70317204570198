import { useForm, Controller } from "react-hook-form";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import Button from "../../../components/button/Button";
import { FormInput } from "../../../components/formComponents/FormComponents";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const ShippingHandleSchema = yup.object({
  package_weight: yup
    .number()
    .moreThan(0, "Weight must be greater than zero")
    .required("Weight is required"),
  package_width: yup
    .number()
    .min(1, "Width must be equal or greater than 1")
    .required("Width is required"),
  package_height: yup
    .number()
    .min(1, "Height must be equal or greater than 1")
    .required("Height is required"),
  package_length: yup
    .number()
    .min(1, "Length must be equal or greater than 1")
    .required("Length is required"),
  logistic_company_slug: yup.string().nullable(),
});

const ShippingModal = (props) => {
  const { openModal, handleclose, onSubmitForm } = props;
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ShippingHandleSchema),
    defaultValues: {
      package_weight: "",
      package_width: "",
      package_height: "",
      package_length: "",
      logistic_company_slug: "DHL",
    },
  });

  const onSubmit = (data) => {
    onSubmitForm(data); // Call parent function to handle API submission
  };

  const onhandelclose = () => {
    reset();
    handleclose(); // Close modal after successful submission
  };

  return (
    <Dialog
      open={openModal}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onhandelclose(); // Prevent closing when clicking outside
        }
      }}
    >
      <DialogTitle>DHL Shipping Details 📦</DialogTitle>
      <DialogContent dividers>
        <div className="formmodalinputs">
          <Controller
            name="package_weight"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormInput
                required
                label="Weight in (kg)"
                placeholder="Enter Weight"
                name="package_weight"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                helperText={error?.message}
                hideVariant={true}
                type="number"
              />
            )}
          />
          <Controller
            name="package_width"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormInput
                required
                label="Width in (cm)"
                placeholder="Enter Width"
                name="package_width"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                helperText={error?.message}
                hideVariant={true}
                type="number"
              />
            )}
          />
        </div>
        <div className="formmodalinputs">
          <Controller
            name="package_height"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormInput
                required
                label="Height in (cm)"
                placeholder="Enter Height"
                name="package_height"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                helperText={error?.message}
                hideVariant={true}
                type="number"
              />
            )}
          />
          <Controller
            name="package_length"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormInput
                required
                label="Length in (cm)"
                placeholder="Enter Length"
                name="package_length"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                helperText={error?.message}
                hideVariant={true}
                type="number"
              />
            )}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          name="Save"
          selected
          width="30%"
          type="submit"
          onClick={handleSubmit(onSubmit)}
        />
        <Button name="Cancel" width="30%" type="button" onClick={onhandelclose} />
      </DialogActions>
    </Dialog>
  );
};

export default ShippingModal;
