//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
//COMPONENT
import { Card, Skeleton, Button as ButtonMui } from "@mui/material";
import "./QuestionsHandle.scss";
import { PlusCircleSVG, DeleteSVG } from "../../../assests/icons/SVG";

// CUSTOM HOOKS
import { useGet } from "../../../hooks/useFetch";
import { AlertContext } from "../../../context/AlertContext";

// FORM
import {
  FormInput,
  FormMultiSelect,
  FormDropdownList,
  FormCheckBox,
  FormSwitch,
} from "../../../components/formComponents/FormComponents";
import Button from "../../../components/button/Button";
import { api } from "../../../core/apis/main";
import {
  createQuestions,
  getQuestionByID,
  updateQuestions,
} from "../../../core/apis/questions";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import { USAFlagIcon } from "../../../assests/icons/SVG";
import KSAFlag from "../../../assests/Images/united-ara.png";
import { translateByGoogleApi } from "../../../core/apis/newlanguage";
import CustomTab from "../../../components/Tabs/customTab";
import { useGlobalValues } from "../../../context/GlobalContext";
import Cropper from "../../../components/singleUpload/imageCropper";
import { deleteAnswer } from "../../../core/apis/questions";
import NoticeConfirmation from "../../../components/dialogs/noticeConfirmation/NoticeConfirmation";

const HandleSchema = yup.object({
  question: yup
    .string("Invalid Field")
    .test("len", "Must be less then 255 characters", (val) => val.length <= 255)
    .required("Field is required"),
  answers: yup.array().of(
    yup.object().shape({
      answer: yup.string("Invalid Field").required("Field is required"),
      description: yup.string("Invalid Field"),
      condition_id: yup.object().nullable().required("Field is required"),
      break: yup.bool(),
      image: yup.mixed(),
    })
  ),
  parent_categories: yup
    .array()
    .min(1, "Field is required")
    .required("Field is required"),
  sub_categories: yup.array().of(
    yup.object().shape({
      model_id: yup.object(),
      value: yup.array(),
    })
  ),
  picea_enabled: yup.bool(),
  is_end_user: yup.bool(),
  valID: yup.string().nullable(),
});

const QuestionsHandle = (props) => {
  // DATA AND HOOKS

  let state = useLocation()?.state;
  let create = state?.state?.create || state?.create;
  const params = useParams();
  const { setAlert } = useContext(AlertContext);
  const [saveLoading, setSaveLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);

  const [imageVal, setImageValue] = useState(null);

  let navigate = useNavigate();

  const { enabledLanguages } = useGlobalValues();
  const [openDelete, setOpenDelete] = useState(false); //bool delete dialog
  const [selectedDataIndex, setSelectedDataIndex] = useState(null); //selected data when opening delete dialog
  const [selectedData, setSelectedData] = useState(null); //selected data when opening delete dialog

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(HandleSchema),
    reValidateMode: "onChange" | "onBlur",
  });

  const {
    fields: modelIdsFields,
    remove: modelIdsRemove,
    append: modelIdsAppend,
  } = useFieldArray({ control, name: "sub_categories" });

  const {
    fields: answersIdFields,
    remove: answersIdRemove,
    append: answersIdAppend,
  } = useFieldArray({ control, name: "answers" });

  const { data: categories, categoriesLoading } = useGet({
    url: "admin/get-all-categories",
    payload: null,
  });

  const { data: conditions, conditionsLoading } = useGet({
    url: "user/get-all-conditions",
    payload: null,
  });

  let categoriesArray = categories?.data?.data?.categories;
  let conditionsArray = conditions?.data?.data;

  const [tabvalue, setTabValue] = useState("en");

  //FUNCTIONS

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getDataCategory = async (categoryValue, categoryIndex) => {
    setLoadingData(true);
    return await api
      .get(`admin/get-category-by-id/${categoryValue?.id}`)
      .then((res) => {
        if (res?.data?.success) {
          modelIdsAppend({
            model_id: res?.data?.data?.category,
            value: [],
          });
        }

        setLoadingData(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleAddSubCategoryFromSub = (value, index) => {
    //remove all subs under this category where the changes where made
    getValues("sub_categories")?.map((el, subIndex) => {
      if (subIndex > index) {
        modelIdsRemove(subIndex);
      }
    });
    // recheck if the value is one then we have to pass to the child
    if (value?.length === 1 && value?.[0]?.has_child) {
      getDataCategory(value[0], index);
    }
  };

  const handleAddSubModelIdsFromMain = (value) => {
    //if categories we should reset the subs ; and if length equal to 1 we get the data of new child
    modelIdsRemove();
    if (value?.length === 1 && value?.[0]?.has_child) {
      getDataCategory(value[0], null);
    }
  };

  //FUNCTIONS
  const handleAddAnswer = () => {
    answersIdAppend({
      answer: "",
      description: "",
      condition_id: null,
      image: null,
      break: false,
    });
  };

  const handleRemoveAddedAnswer = (id, index) => {
    setOpenDelete(true);
    setSelectedData(id);
    setSelectedDataIndex(index);
  };

  // Handles the form on submit, adjusts the data: categories into array of integers, answers into array of objects
  const handleSubmitForm = (formData) => {
    setSaveLoading(true);
    let all_categories = [];
    if (formData?.sub_categories?.length !== 0) {
      formData?.sub_categories
        ?.slice(-1)
        ?.map((val) =>
          val.value?.length !== 0
            ? val.value?.map((el) => all_categories.push(el.id))
            : all_categories.push(val?.model_id?.id)
        );
    } else {
      formData?.parent_categories?.map((val) => all_categories.push(val?.id));
    }
    if (create) {
      let valuesData = new FormData();
      valuesData.append("question", formData.question);
      valuesData.append("language", tabvalue);
      valuesData.append("picea_enabled", formData.picea_enabled ? 1 : 0);
      valuesData.append("is_end_user", formData.is_end_user ? 1 : 0);

      if (formData && formData.answers) {
        formData.answers.forEach((el, index) => {
          valuesData.append(`answers[${index}][answer]`, el.answer);
          valuesData.append(`answers[${index}][description]`, el.description);
          valuesData.append(
            `answers[${index}][condition_id]`,
            el.condition_id?.id
          );
          valuesData.append(`answers[${index}][image]`, el.image);
          valuesData.append(`answers[${index}][break]`, el.break ? 1 : 0);
        });
      }

      const categoriesString = all_categories.join(",");

      valuesData.append(`categories`, categoriesString);

      createQuestions(valuesData).then((res) => {
        if (res?.data?.success) {
          setTimeout(() => {
            translateByGoogleApiFunction(
              res.data.data,
              res.data.data.id,
              all_categories
            );
          }, 50);
        }

        setAlert({
          visible: true,
          text: res.data ? res.data.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
      });
    } else {
      let valuesData = new FormData();
      valuesData.append("id", params?.id);
      valuesData.append("question", formData.question);
      valuesData.append("language", tabvalue);
      valuesData.append("picea_enabled", formData.picea_enabled ? 1 : 0);
      valuesData.append("is_end_user", formData.is_end_user ? 1 : 0);
      formData.answers.forEach((el, index) => {
        if (el?.answer_id) {
          valuesData.append(`answers[${index}][answer_id]`, el.answer_id);
        }
        valuesData.append(`answers[${index}][answer]`, el.answer);
        valuesData.append(`answers[${index}][description]`, el.description);
        valuesData.append(
          `answers[${index}][condition_id]`,
          el.condition_id?.id
        );
        if (el.image instanceof File === true) {
          valuesData.append(`answers[${index}][image]`, el.image);
        }
        valuesData.append(`answers[${index}][break]`, el.break ? 1 : 0);
      });
      const categoriesString = formData.parent_categories.map(
        (value) => value.id
      );
      valuesData.append(`categories`, categoriesString.join(","));
      updateQuestions(params?.id, valuesData).then((res) => {
        if (res?.data?.success) {
          navigate("/set-of-questions");
        }
        setAlert({
          visible: true,
          text: res.data ? res.data.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
      });
    }
  };

  // get Text from api and update form data
  const updateTextTranslation = (
    formData,
    id,
    all_categories,
    targetLanguage
  ) => {
    let valuesData = new FormData();
    valuesData.append("question", formData.question);
    valuesData.append("language", targetLanguage);
    valuesData.append("picea_enabled", formData.picea_enabled ? 1 : 0);
    valuesData.append("is_end_user", formData.is_end_user ? 1 : 0);
    formData.answers.forEach((el, index) => {
      valuesData.append(`answers[${index}][answer_id]`, el.answer_id);
      valuesData.append(`answers[${index}][answer]`, el.answer);
      valuesData.append(`answers[${index}][description]`, el.description);
      valuesData.append(`answers[${index}][condition_id]`, el.condition_id);
      valuesData.append(`answers[${index}][break]`, el.break ? 1 : 0);
    });

    const categoriesString = all_categories.join(",");

    valuesData.append(`categories`, categoriesString);

    updateQuestions(id, valuesData).then((res) => {
      if (res?.data?.success) {
        navigate("/set-of-questions");
      }
      setAlert({
        visible: true,
        text: res.data ? res.data.message : res.message,
        type: res.data?.success ? "success" : "error",
      });
    });
  };

  const translateByGoogleApiFunction = async (formData, id, all_categories) => {
    // Map the answers array to include necessary properties
    formData.answers = formData?.answers?.map((el) => ({
      answer_id: el?.id,
      answer: el?.answer?.en,
      description: el?.description?.en,
      condition_id: el?.condition_id,
      break: el?.is_breakable,
    }));

    // Extract questions, answers, and descriptions for translation
    let questions = formData.question.en;
    let answers = formData.answers.map((item) => item.answer);
    let descriptions = formData.answers.map((item) => item.description);

    // Combine all text to be translated into a single array
    let arrayofdata = [questions, ...answers, ...descriptions];

    // Iterate over enabled languages for translation
    enabledLanguages.map((item) => {
      // Skip translation if language is English
      if (item.code !== "en") {
        // Translate text array using Google Translate API
        translateByGoogleApi(arrayofdata, item.code).then((res) => {
          // Extract translated question, answers, and descriptions
          let translatedQuestion = res[0]?.translatedText;
          let translatedAnswers = res
            .slice(1, formData.answers.length + 1)
            .map((r) => r.translatedText);
          let translatedDescriptions = res
            .slice(formData.answers.length + 1)
            .map((r) => r.translatedText);

          // Update form data with translated text
          formData.display_name = translatedQuestion;
          formData.question = translatedQuestion;

          // Update each answer with translated text
          formData.answers.forEach((answer, index) => {
            answer.answer = translatedAnswers[index];
            answer.description = translatedDescriptions[index];
          });

          // Call updateTextTranslation function with translated data
          updateTextTranslation(formData, id, all_categories, item.code);
        });
      }
    });
  };

  const getQuestionData = () => {
    setLoading(true);
    getQuestionByID(params?.id).then((res) => {
      if (res?.data?.success) {
        let questionData = res?.data.data?.[0];
        reset({
          display_name:
            questionData?.question?.[tabvalue] || questionData?.question?.en,
          question:
            questionData?.question?.[tabvalue] || questionData?.question?.en,
          parent_categories: questionData?.category, //bl edit no edit 3l categories , and here backend are not sending the children that's why we are doing that
          sub_categories: [],
          answers: questionData?.answer
            ?.sort((a, b) => a.id - b.id)
            ?.map((el) => ({
              answer_id: el?.id,
              answer: el?.answer?.[tabvalue] || el?.answer?.en,
              description: el?.description?.[tabvalue] || el?.description?.en,
              condition_id: el?.condition,
              image: el?.image_path,
              break: el?.is_breakable,
            })),
          picea_enabled: questionData?.picea_enabled,
          is_end_user: questionData?.is_end_user,
        });
      }
      setLoading(false);
    });
  };

  // Delete confirmation modal if yes, make the request
  const handleDeleteResponse = async (type, selectedData) => {
    if (type === "yes") {
      deleteAnswer(selectedData)
        .then((res) => {
          setAlert({
            visible: true,
            text: res.data.message,
            type: res.data.success ? "success" : "error",
          });
          answersIdRemove(selectedDataIndex);
          setOpenDelete(false);
          setSelectedData(null);
          setSelectedDataIndex(null);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setOpenDelete(false);
      setSelectedData(null);
      setSelectedDataIndex(null);
    }
  };

  // Setting form values after fetching data on edit
  useEffect(() => {
    if (!create) {
      getQuestionData();
    } else {
      reset({
        question: "",
        picea_enabled: false,
        is_end_user: false,
        answers: [
          {
            answer: "",
            description: "",
            condition_id: null,
            image: null,
            break: false,
          },
        ],
        language: tabvalue,
      });
    }
  }, [create, tabvalue]);

  return !loading ? (
    <>
      <h1 className={tabvalue != "ar" ? "" : " arabic-component"}>
        {create ? "New Question" : getValues("display_name")}
      </h1>
      <TabContext value={tabvalue}>
        <Tabs
          value={tabvalue}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          {enabledLanguages.map((item, index) =>
            create && item.code === "en" ? (
              <CustomTab
                index={index}
                value={item.code}
                label={item.name}
                icon={<USAFlagIcon />}
              />
            ) : (
              !create && (
                <CustomTab
                  index={index}
                  value={item.code}
                  label={item.name}
                  icon={
                    item.code == "en" ? (
                      <USAFlagIcon />
                    ) : item.code == "ar" ? (
                      <img src={KSAFlag} width="30" />
                    ) : (
                      ""
                    )
                  }
                />
              )
            )
          )}
        </Tabs>

        <form
          className={
            tabvalue != "ar" ? "addQForm" : " addQForm arabic-component"
          }
          onSubmit={handleSubmit(handleSubmitForm)}
        >
          <Card className="addCard">
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormInput
                  required
                  name="question"
                  label="Question"
                  placeholder={"Enter question"}
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  helperText={error?.message}
                />
              )}
              name="question"
              control={control}
            />
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormMultiSelect
                  required
                  fixedOptions={!create ? value : []}
                  disabled={!create ? true : false}
                  loading={categoriesLoading}
                  clearErrors={clearErrors}
                  dependencyError={["parent_categories", "sub_categories"]}
                  data={categoriesArray || []}
                  label={"Category"}
                  name="parent_categories"
                  placeholder={`Select category`}
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    handleAddSubModelIdsFromMain(value);
                  }}
                  helperText={error?.message}
                />
              )}
              name={"parent_categories"}
              control={control}
            />

            {getValues("sub_categories")?.map((val, index) => (
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormMultiSelect
                    required
                    fixedOptions={!create ? value : []}
                    disabled={!create ? true : false}
                    loading={false}
                    data={val?.model_id?.child || []}
                    label={val?.model_id?.name?.en}
                    name={`sub_categories.${index}.value`}
                    placeholder={`Select ${val?.model_id?.name?.en}`}
                    value={value}
                    onChange={(value) => {
                      onChange(value);
                      handleAddSubCategoryFromSub(value, index);
                    }}
                    helperText={error?.message}
                  />
                )}
                name={`sub_categories.${index}.value`}
                control={control}
              />
            ))}
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormSwitch
                  label="Store Question"
                  name="is_end_user"
                  value={value}
                  onChange={(e) => {
                    onChange(e?.target?.checked);
                  }}
                />
              )}
              name="is_end_user"
              control={control}
            />
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormSwitch
                  label="Picea"
                  name="picea_enabled"
                  value={value}
                  onChange={(e) => {
                    onChange(e?.target?.checked);
                  }}
                />
              )}
              name="picea_enabled"
              control={control}
            />
          </Card>

          {answersIdFields?.length !== 0 &&
            answersIdFields?.map((el, answerIndex) => {
              return (
                <Card
                  key={`${answerIndex}_${answersIdFields.length}`}
                  className="addCard"
                >
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        required
                        name={`answers.${answerIndex}.answer`}
                        label={`Answer ${answerIndex + 1}`}
                        placeholder={"Enter answer"}
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        helperText={error?.message}
                      />
                    )}
                    name={`answers.${answerIndex}.answer`}
                    control={control}
                  />
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormInput
                        name={`answers.${answerIndex}.description`}
                        label="Description"
                        placeholder={"Enter description"}
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        helperText={error?.message}
                      />
                    )}
                    name={`answers.${answerIndex}.description`}
                    control={control}
                  />
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormDropdownList
                        required
                        name={`answers.${answerIndex}.condition_id`}
                        label="Condition"
                        data={conditionsArray || []}
                        loading={conditionsLoading}
                        placeholder={"Enter condition"}
                        value={value || null}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        helperText={error?.message}
                      />
                    )}
                    name={`answers.${answerIndex}.condition_id`}
                    control={control}
                  />

                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <div>
                        <Cropper
                          imageSrc={value}
                          sendCroppedFile={(e) => onChange(e)}
                          MAX_IMAGE_WIDTH={400}
                          MAX_IMAGE_HEIGHT={500}
                          sizeMsg={"500*500"}
                          iwebp={true}
                        />
                      </div>
                    )}
                    name={`answers.${answerIndex}.image`}
                    control={control}
                  />

                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormCheckBox
                        name={`answers.${answerIndex}.break`}
                        label="Breakable"
                        value={value == true ? true : false}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        helperText={error?.message}
                      />
                    )}
                    name={`answers.${answerIndex}.break`}
                    control={control}
                  />
                  {answersIdFields.length > 1 && (
                    <div className="action-footer">
                      {create || !answersIdFields[answerIndex]?.answer_id ? (
                        <ButtonMui onClick={() => answersIdRemove(answerIndex)}>
                          Remove
                        </ButtonMui>
                      ) : (
                        <ButtonMui
                          onClick={() => {
                            handleRemoveAddedAnswer(
                              answersIdFields[answerIndex]?.answer_id,
                              answerIndex
                            );
                          }}
                        >
                          Remove Answer
                        </ButtonMui>
                      )}
                    </div>
                  )}
                </Card>
              );
            })}

          <div onClick={() => handleAddAnswer()} className="add">
            <PlusCircleSVG />
            <p>Add New Answer</p>
          </div>
          <div className="action-footer">
            <Button
              name={create ? "Create" : "Save"}
              type="submit"
              selected
              loading={saveLoading}
              disabled={saveLoading || loadingData}
            />
          </div>
        </form>
      </TabContext>

      {openDelete && (
        <NoticeConfirmation
          data={selectedData}
          handleResponse={(e) => handleDeleteResponse(e, selectedData)}
        />
      )}
    </>
  ) : (
    <Skeleton count={10} />
  );
};

export default QuestionsHandle;
