import React, { useState } from "react";
import { Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CommonMetaDetails from "../About/common-meta-section";

const ManageMetaData = () => {
  const [tabValue, setTabValue] = useState("HOME_PAGE_META_SPECS");

  const metaSpecs = [
    { id: "HOME_PAGE_META_SPECS", name: "Home Page Meta" },
    { id: "ABOUT_PAGE_META_SPECS", name: "About Page Meta" },
    { id: "BUSINESS_PAGE_META_SPECS", name: "Business Page Meta" },
    { id: "FAQ_PAGE_META_SPECS", name: "FAQ Page Meta" },
    { id: "HELP_CENTER_PAGE_META_SPECS", name: "Help Center Page Meta" },
    { id: "SERVICES_PAGE_META_SPECS", name: "Services Page Meta" },
    { id: "BLOG_LISTING_PAGE_META_SPECS", name: "Blog Listing Page Meta" },
    { id: "SELL_MY_DEVICE_CATEGORY_SELECTION_PAGE_META_SPECS", name: "Sell My Device - Category Meta" },
    { id: "SELL_MY_DEVICE_BRAND_SELECTION_PAGE_META_SPECS", name: "Sell My Device - Brand  Meta"  },
    { id: "SELL_MY_DEVICE_SUBCATEGORY_SELECTION_PAGE_META_SPECS", name: "Sell My Device - Subcategory Meta"}
  ];
  
  console.log(metaSpecs);
  

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const updateName = (str) => {
    return str?.toLowerCase()?.replace(/_/g, " ")?.toUpperCase();
  };

  return (
    <div className="lawandorder">
      <TabContext value={tabValue}>
        <TabList
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          {metaSpecs?.map((tab) => (
            <Tab
              key={tab}
              label={updateName(tab.name)}
              value={tab.id}
              iconPosition="start"
              style={{ marginTop: "10px" }}
            />
          ))}
        </TabList>
        {metaSpecs?.map((tab) => (
          <TabPanel value={tab.id} style={{ padding: "0px" }}>
            <CommonMetaDetails metatype={tab.id} />
          </TabPanel>
        ))}
      </TabContext>
    </div>
  );
};

export default ManageMetaData;
