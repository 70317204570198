import React, { useEffect, useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AlertContext } from "../../../../context/AlertContext";
import * as yup from "yup";
import { useGet } from "../../../../hooks/useFetch";
import "./privacyPolicy.scss";
import NewEditor from "../../../../components/editor/NewEditor";
import { FormInput } from "../../../../components/formComponents/FormComponents";
import { useGlobalValues } from "../../../../context/GlobalContext";
import { updateExistingDocument } from "../../../../core/apis/store";
import Button from "../../../../components/button/Button";
import Cropper from "../../../../components/singleUpload/imageCropper";
import { Skeleton } from "@mui/material";
import MetaFieldsForm from "../../../../components/formComponents/metadynamicform";
import TabPanel from "@mui/lab/TabPanel";
import { Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import WidgetsIcon from "@mui/icons-material/Widgets";
import StoreIcon from "@mui/icons-material/Store";

const PrivacyPolicy = (props) => {
  const { doctype } = props;
  // Validation Schema
  const privacyPolicySchema = yup.object({
    title: yup.string().nullable().required("Field is required"),
    description: yup.string().nullable().required("Field is required"),
    Image: yup.mixed(),
    metatitle: yup
      .string()
      .required("Meta title is required")
      .max(60, "Meta title must be at most 60 characters"),
    metadescription: yup
      .string()
      .required("Meta description is required")
      .max(160, "Meta description must be at most 160 characters"),
    metakeywords: yup
      .array()
      .min(1, "At least one keyword is required")
      .max(10, "You can add up to 10 keywords only"), // ✅ Limit to 10 items
  });

  const { storeLanguage } = useGlobalValues();
  const [loading, setLoading] = useState(false);
  const [fetchingdata, setfetchingdata] = useState(true);
  const [imageVal, setImageValue] = useState(null);

  const { setAlert } = useContext(AlertContext);

  // // Get Details by Document Types
  const {
    data: detailsbyTypes,
    loading: loadingAlldocumenttypes,
    refetch,
  } = useGet({
    url: `admin/document/${doctype}`,
  });

  let detailsbyTypesArray = detailsbyTypes?.data?.data;

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(privacyPolicySchema) });

  const handleImage = (croppedImage) => {
    setImageValue(croppedImage);
    setValue("Image", croppedImage);
  };

  // Fetch data and populate form if ID is provided
  useEffect(() => {
    if (detailsbyTypesArray) {
      setValue("title", detailsbyTypesArray?.title?.[storeLanguage]);
      setValue("description", detailsbyTypesArray?.paragraph_content);
      setValue("Image", detailsbyTypesArray?.image);
      setValue(
        "metatitle",
        detailsbyTypesArray?.meta_title?.[storeLanguage] || ""
      );
      setValue(
        "metadescription",
        detailsbyTypesArray?.meta_description?.[storeLanguage] || ""
      );
      setValue(
        "metakeywords",
        detailsbyTypesArray?.meta_keywords?.[storeLanguage]
          ?.split(",")
          ?.map((item, index) => ({
            tag_name: item.trim(),
            id: item.trim(), // Assigning a unique ID (you can modify it as needed)
          })) || []
      );
      setfetchingdata(false);
    }
  }, [detailsbyTypesArray]);

  // Fetch data and populate form if ID is provided
  useEffect(() => {
    setfetchingdata(true);
    refetch();
  }, [storeLanguage]);

  const handleSubmitForm = async (values) => {
    setLoading(true);
    let metaTags = values.metakeywords.map((tag) => tag.tag_name).join(", ");
    let formData = new FormData();
    formData.append("title", values.title);
    formData.append("paragraph_content", values.description);
    formData.append("language", storeLanguage);
    formData.append("meta_title", values.metatitle);
    formData.append("meta_description", values.metadescription);
    formData.append("meta_keywords", metaTags);

    if (
      doctype === "SHIPPING_INFORMATION" ||
      doctype === "PAYMENT_INFORMATION"
    ) {
      formData.append("image", values.Image);
    }

    if (!(values?.Image instanceof File)) {
      formData.delete("image");
    }

    updateExistingDocument(doctype, formData).then((res) => {
      setAlert({
        visible: true,
        text: res?.data?.success ? "Successfully updated" : res?.data?.message,
        type: res?.data?.success ? "success" : "error",
      });
      refetch();
      setLoading(false);
    });
  };
  const handleCancel = () => {
    setfetchingdata(true);
    refetch();
  };

  const [tabdetailsvalue, setTabDetailsValue] = useState("0");

  const handleTabChange = (event, value) => {
    setTabDetailsValue(value);
  };

  return (
    <TabContext value={tabdetailsvalue}>
      <TabList
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        <Tab
          label={"Document Details"}
          value={"0"}
          icon={<WidgetsIcon />}
          iconPosition="start"
        />
        <Tab
          label={"Meta Details"}
          value={"1"}
          icon={<StoreIcon />}
          iconPosition="start"
        />
      </TabList>
      <div className="PrivacyPolicy">
        <div className="form-section">
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            {/* Skeleton Loader for Title */}

            <TabPanel value="0">
              {fetchingdata ? (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={56}
                  style={{ marginBottom: "16px" }}
                />
              ) : (
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormInput
                      required
                      name="title"
                      label="Page Title"
                      hideVariant
                      placeholder={"Privacy Policy"}
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      helperText={error?.message}
                    />
                  )}
                  name="title"
                  control={control}
                />
              )}

              <div className="form-editor">
                {/* Skeleton Loader for Description */}
                {fetchingdata ? (
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={200}
                    style={{ marginBottom: "16px" }}
                  />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <NewEditor
                        label={"Description"}
                        name="description"
                        labelclassname="label-editor-title"
                        value={value}
                        lang={storeLanguage}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                    name="description"
                    control={control}
                  />
                )}
              </div>

              {doctype === "SHIPPING_INFORMATION" ||
              doctype === "PAYMENT_INFORMATION" ? (
                fetchingdata ? (
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={300}
                    style={{ marginBottom: "16px" }}
                  />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Cropper
                        required
                        sendCroppedFile={handleImage}
                        MAX_IMAGE_WIDTH={500}
                        MAX_IMAGE_HEIGHT={500}
                        title={"Drag your Image here"}
                        className="width100"
                        isTrue={true}
                        iwebp={true}
                        imageSrc={getValues("Image")}
                      />
                    )}
                    name="Image"
                    control={control}
                  />
                )
              ) : null}
            </TabPanel>
            <TabPanel value="1">
              <MetaFieldsForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                clearErrors={clearErrors}
              />
            </TabPanel>

            <div className="action-footer">
              {fetchingdata ? (
                <>
                  <Skeleton
                    variant="rectangular"
                    width={120}
                    height={40}
                    style={{ marginRight: "16px" }}
                  />
                  <Skeleton variant="rectangular" width={120} height={40} />
                </>
              ) : (
                <>
                  <Button
                    selected
                    name={"Save"}
                    type="submit"
                    loading={loading}
                    className="mx-1"
                  />
                  <Button
                    onClick={() => handleCancel()}
                    name={"Cancel"}
                    type="button"
                    className="mx-1"
                  />
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </TabContext>
  );
};

export default PrivacyPolicy;
