import React, { useState } from "react";
import { Card, CardContent } from "@mui/material";
import { FormTextArea, FormInput, FormMultiSelect } from "./FormComponents";

const MetaFieldsForm = ({ isopenGraph = false, istwitter = false, formik }) => {
  const [keywordsLengthError, setkeywordsLengthError] = useState("");

  return (
    <Card>
      <CardContent>
        <FormInput
          required
          label="Meta Title"
          placeholder="Enter Meta Title 60 characters"
          name="metatitle"
          id="metatitle"
          hideVariant
          value={formik.values.metatitle}
          onChange={formik.handleChange}
          error={formik.touched.metatitle && Boolean(formik.errors.metatitle)}
          helperText={formik.touched.metatitle && formik.errors.metatitle}
        />

        <FormTextArea
          required
          rows="3"
          label="Meta Description"
          placeholder="Enter Meta Description Max Length 160 characters"
          name="metadescription"
          id="metadescription"
          value={formik.values.metadescription}
          onChange={formik.handleChange} // Ensure this is properly set
          error={
            formik.touched.metadescription &&
            Boolean(formik.errors.metadescription)
          }
          helperText={
            formik.touched.metadescription && formik.errors.metadescription
          }
        />
        {/* Meta Tags (MultiSelect) */}
        <FormMultiSelect
          required
          label="Meta Tags"
          placeholder="Enter Meta Tags (Max 10 keywords, 255 characters total)"
          name="metakeywords"
          id="metakeywords"
          isfreeSolo
          data={[]} // Pass available tag options
          hideVariant
          accessValue="tag_name"
          onChange={(selectedTags) => {
            if (!selectedTags) return;

            const formattedTags = selectedTags.map((tag) => ({
              id: tag.id || tag.tag_name,
              tag_name: tag.tag_name || tag.id,
            }));

            const tagsString = formattedTags
              .map((tag) => tag.tag_name)
              .join(", ");

            if (tagsString.length > 255) {
              setkeywordsLengthError(
                "Total length of tags must not exceed 255 characters"
              );
              return;
            } else {
              setkeywordsLengthError("");
            }

            formik.setFieldValue("metakeywords", formattedTags);
          }}
          value={formik.values.metakeywords}
          error={
            keywordsLengthError ||
            (formik.touched.metakeywords && Boolean(formik.errors.metakeywords))
          }
          helperText={
            keywordsLengthError ||
            (formik.touched.metakeywords && formik.errors.metakeywords)
          }
        />

        <div>
          {isopenGraph && (
            <div className="isopenGraph my-2">
              <h4>Open Graph</h4>
              <FormInput
                required
                label="OG Title"
                placeholder="Enter Open Graph Title 60 characters  The title of the shared link."
                name="ogmetatitle"
                id="ogmetatitle"
                hideVariant
                value={formik.values.ogmetatitle}
                onChange={formik.handleChange}
                error={
                  formik.touched.ogmetatitle &&
                  Boolean(formik.errors.ogmetatitle)
                }
                helperText={
                  formik.touched.ogmetatitle && formik.errors.ogmetatitle
                }
              />

              <FormInput
                required
                label="OG Site Name"
                placeholder="Enter Open Graph Site Name 60 characters"
                name="ogsiteName"
                id="ogsiteName"
                hideVariant
                value={formik.values.ogsiteName}
                onChange={formik.handleChange}
                error={
                  formik.touched.ogsiteName && Boolean(formik.errors.ogsiteName)
                }
                helperText={
                  formik.touched.ogsiteName && formik.errors.ogsiteName
                }
              />

              <FormInput
                required
                label="OG Site Type"
                placeholder="Enter Open Graph Site Type 60 characters"
                name="ogsiteType"
                id="ogsiteType"
                hideVariant
                value={formik.values.ogsiteType}
                onChange={formik.handleChange}
                error={
                  formik.touched.ogsiteType && Boolean(formik.errors.ogsiteType)
                }
                helperText={
                  formik.touched.ogsiteType && formik.errors.ogsiteType
                }
              />

              <FormTextArea
                required
                rows="3"
                label="OG Description"
                placeholder="Enter OG Description Max Length 160 characters"
                name="ogmetadescription"
                id="ogmetadescription"
                value={formik.values.ogmetadescription}
                onChange={formik.handleChange} // Ensure this is properly set
                error={
                  formik.touched.ogmetadescription &&
                  Boolean(formik.errors.ogmetadescription)
                }
                helperText={
                  formik.touched.ogmetadescription &&
                  formik.errors.ogmetadescription
                }
              />

              <FormInput
                required
                label="OG Alt Image"
                placeholder="Enter Open Graph Alt Image 60 characters"
                name="ogAltImage"
                id="ogAltImage"
                hideVariant
                value={formik.values.ogAltImage}
                onChange={formik.handleChange}
                error={
                  formik.touched.ogAltImage && Boolean(formik.errors.ogAltImage)
                }
                helperText={
                  formik.touched.ogAltImage && formik.errors.ogAltImage
                }
              />

              <FormInput
                required
                label="OG url"
                placeholder="Enter open Graph url"
                name="ogurl"
                id="ogurl"
                hideVariant
                value={formik.values.ogurl}
                onChange={formik.handleChange}
                error={formik.touched.ogurl && Boolean(formik.errors.ogurl)}
                helperText={formik.touched.ogurl && formik.errors.ogurl}
              />
            </div>
          )}

          {isopenGraph && (
            <div className="isopenGraph my-2">
              <h4>Twitter Graph</h4>
              <FormInput
                required
                label="Twitter Card"
                placeholder="Enter Twitter Card"
                name="twittercard"
                id="twittercard"
                hideVariant
                value={formik.values.twittercard}
                onChange={formik.handleChange}
                error={
                  formik.touched.twittercard &&
                  Boolean(formik.errors.twittercard)
                }
                helperText={
                  formik.touched.twittercard && formik.errors.twittercard
                }
              />

              <FormInput
                required
                label="Twitter Title"
                placeholder="Enter Twitter Title "
                name="twittermetatitle"
                id="twittermetatitle"
                hideVariant
                value={formik.values.twittermetatitle}
                onChange={formik.handleChange}
                error={
                  formik.touched.twittermetatitle &&
                  Boolean(formik.errors.twittermetatitle)
                }
                helperText={
                  formik.touched.twittermetatitle &&
                  formik.errors.twittermetatitle
                }
              />

              <FormTextArea
                required
                rows="3"
                label="Twitter Description"
                placeholder="Enter Twitter Description Max Length 160 characters"
                name="twittermetadescription"
                id="twittermetadescription"
                value={formik.values.twittermetadescription}
                onChange={formik.handleChange} // Ensure this is properly set
                error={
                  formik.touched.twittermetadescription &&
                  Boolean(formik.errors.twittermetadescription)
                }
                helperText={
                  formik.touched.twittermetadescription &&
                  formik.errors.twittermetadescription
                }
              />

              <FormInput
                required
                label="Twitter Site Type"
                placeholder="Enter Twitter Site Type"
                name="twittersiteType"
                id="twittersiteType"
                hideVariant
                value={formik.values.twittersiteType}
                onChange={formik.handleChange}
                error={
                  formik.touched.twittersiteType &&
                  Boolean(formik.errors.twittersiteType)
                }
                helperText={
                  formik.touched.twittersiteType &&
                  formik.errors.twittersiteType
                }
              />

              <FormInput
                required
                label="Twitter Creator"
                placeholder="Enter Twitter Creator"
                name="twittercreator"
                id="twittercreator"
                hideVariant
                value={formik.values.twittercreator}
                onChange={formik.handleChange}
                error={
                  formik.touched.twittercreator &&
                  Boolean(formik.errors.twittercreator)
                }
                helperText={
                  formik.touched.twittercreator && formik.errors.twittercreator
                }
              />

              <FormInput
                required
                label="Twitter Alt Image"
                placeholder="Enter Twitter ALt Image"
                name="twitterAltImage"
                id="twitterAltImage"
                hideVariant
                value={formik.values.twitterAltImage}
                onChange={formik.handleChange}
                error={
                  formik.touched.twitterAltImage &&
                  Boolean(formik.errors.twitterAltImage)
                }
                helperText={
                  formik.touched.twitterAltImage &&
                  formik.errors.twitterAltImage
                }
              />

              <FormInput
                required
                label="Twitter url"
                placeholder="Enter Twitter url"
                name="twitterurl"
                id="twitterurl"
                hideVariant
                value={formik.values.twitterurl}
                onChange={formik.handleChange}
                error={
                  formik.touched.twitterurl && Boolean(formik.errors.twitterurl)
                }
                helperText={
                  formik.touched.twitterurl && formik.errors.twitterurl
                }
              />
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default MetaFieldsForm;
