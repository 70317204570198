import React, { useState, useEffect, useContext } from "react";
import "../Categories.scss";
import Button from "../../../../components/button/Button";
import { Card, CardContent, FormHelperText } from "@mui/material";
import { InputLabel } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  FormMultiSelect,
  FormSwitch,
  UploadComponent,
} from "../../../../components/formComponents/FormComponents";
import { FormInput } from "../../../../components/formComponents/FormComponents";
import { useGet } from "../../../../hooks/useFetch";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createCategory,
  getCategoryDetails,
} from "../../../../core/apis/category";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import CustomTab from "../../../../components/Tabs/customTab";
import { USAFlagIcon } from "../../../../assests/icons/SVG";
import KSAFlag from "../../../../assests/Images/united-ara.png";
import CircularProgress from "@mui/material/CircularProgress";

import * as yup from "yup";
import { AlertContext } from "../../../../context/AlertContext";
import { api } from "../../../../core/apis/main";
import { useGlobalValues } from "../../../../context/GlobalContext";
import { translateByGoogleApi } from "../../../../core/apis/newlanguage";
import MetaFieldsForm from "../../../../components/formComponents/metadynamicform";

const HandleSchema = yup.object({
  name: yup
    .string("Enter a category name")
    .required("Category name is required"),
  description: yup.string("Enter a description").nullable(),
  abbreviation: yup.string("").required("Field is Required"),
  item_identity_id: yup.array(),
  sell: yup.bool(),
  buy: yup.bool(),
  active: yup.bool(),
  type_message: yup.bool().when(["active", "sell", "buy"], {
    is: (activeBool, sellBool, buyBool) =>
      activeBool === true && sellBool === false && buyBool === false,
    then: yup.bool().required("Please select a type for this category"),
    otherwise: yup.bool(),
  }),
  picea_enabled: yup.bool(),
  coming_soon: yup.bool(),
  image: yup.mixed().required("Field is required"),
  metatitle: yup
    .string()
    .required("Meta title is required")
    .max(60, "Meta title must be at most 60 characters"),
  metadescription: yup
    .string()
    .required("Meta description is required")
    .max(160, "Meta description must be at most 160 characters"),
  metakeywords: yup
    .array()
    .min(1, "At least one keyword is required")
    .max(10, "You can add up to 10 keywords only"), // ✅ Limit to 10 items
});

export function CategoryHandle(props) {
  //VARIABLES
  const { create } = props;
  const { setAlert } = useContext(AlertContext);
  const { id } = useParams();
  let navigate = useNavigate();
  let { parent_id } = useLocation().state || {};
  const [saveLoading, setSaveLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [tabvalue, setTabValue] = useState("en");
  const [detailsData, setDetailsData] = useState("en");

  const { enabledLanguages } = useGlobalValues();

  const {
    control,
    handleSubmit,
    register,
    setValue,
    getValues,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });

  //API
  const { data: itemIdentities, loading: loadingIdentities } = useGet({
    url: `admin/get-all-item-identities`,
  });

  //FNCTIONS
  const handleSubmitForm = (formData) => {
    setSaveLoading(true);
    let metaTags = formData.metakeywords.map((tag) => tag.tag_name).join(", ");
    let valuesData = new FormData();
    valuesData.append("name", formData?.name);
    valuesData.append("description", formData?.description);
    valuesData.append("abbreviation", formData?.abbreviation);
    valuesData.append("buy", formData?.buy);
    valuesData.append("sell", formData?.sell);
    valuesData.append("active", formData?.active);
    formData?.parent_category_id &&
      valuesData.append("parent_category_id", formData?.parent_category_id);
    valuesData.append("coming_soon", formData?.coming_soon);
    valuesData.append("picea_enabled", formData?.picea_enabled);
    valuesData.append("language", formData.language);

    valuesData.append("meta_title", formData.metatitle);
    valuesData.append("meta_description", formData.metadescription);
    valuesData.append("meta_keywords", metaTags);

    // UPDATE CONDITION INORDER TO NOT SEND IT NULL
    if (formData?.item_identity_id !== undefined) {
      valuesData.append(
        "item_identity_id",
        formData?.item_identity_id?.map((el) => el?.id).toString()
      );
    }

    valuesData.append("image", formData?.image);

    if (!create) {
      if (formData?.image instanceof File === false) {
        valuesData.delete("image");
      }

      api.post(`admin/update-category/${id}`, valuesData).then((res) => {
        if (res?.data?.success) {
          navigate(
            `/categories${
              formData?.parent_category_id
                ? `/?sub=${formData?.parent_category_id}`
                : ""
            }`
          );
        }
        setAlert({
          visible: true,
          text: res.data ? res.data.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
        setSaveLoading(false);
      });
    } else {
      createCategory(valuesData).then((res) => {
        console.log(valuesData);
        if (res?.data?.success) {
          enabledLanguages.map((item) => {
            if (item.code != "en") {
              console.log(res.data.data);
              handleTranslateByGoogle(res.data.data, valuesData, item.code);
            }
          });
        }
        setAlert({
          visible: true,
          text: res.data ? res.data.message : res.message,
          type: res.data?.success ? "success" : "error",
        });
        setSaveLoading(false);
      });
    }
  };

  useEffect(() => {
    if (create) {
      reset({
        buy: true,
        sell: true,
        active: true,
        type: false,
        coming_soon: false,
        parent_category_id: parent_id || null,
        name: "",
        description: "",
        abbreviation: "",
        picea_enabled: false,
        item_identity_id: [],
        language: tabvalue,
        metatitle: "",
        metadescription: "",
        metakeywords: [],
      });
    } else {
      setLoadingData(true);
      getCategoryDetails(id).then((res) => {
        if (res?.data?.success) {
          setDetailsData(res);
          // console.log(res);
        }
        setLoadingData(false);
      });
    }
  }, []);

  useEffect(() => {
    if (detailsData) {
      reset({
        parent_category_id:
          parent_id || detailsData?.data?.data?.category?.parent_id || null,
        display_name: detailsData?.data?.data?.category?.name?.[tabvalue] || "",
        name: detailsData?.data?.data?.category?.name?.[tabvalue] || "",
        description:
          detailsData?.data?.data?.category?.description?.[tabvalue] || "",
        abbreviation: detailsData?.data?.data?.category.abbreviation || "",

        item_identity_id: detailsData?.data?.data?.category?.item_identity,
        buy: detailsData?.data?.data?.category?.is_buy || false,
        sell: detailsData?.data?.data?.category?.is_sell || false,
        active: detailsData?.data?.data?.category?.is_active || false,
        coming_soon: detailsData?.data?.data?.category?.is_coming_soon || false,
        image: detailsData?.data?.data?.category?.image_path || null,
        picea_enabled: detailsData?.data?.data?.category.picea_enabled || false,

        metatitle:
          detailsData?.data?.data?.category?.meta_title?.[tabvalue] || "",
        metadescription:
          detailsData?.data?.data?.category?.meta_description?.[tabvalue] || "",
        metakeywords:
          detailsData?.data?.data?.category?.meta_keywords?.[tabvalue]
            ?.split(",")
            ?.map((item, index) => ({
              tag_name: item.trim(),
              id: item.trim(), // Assigning a unique ID (you can modify it as needed)
            })) || [],

        language: tabvalue,
        type: false,
      });
    }
  }, [detailsData, tabvalue]);

  const handleTranslateByGoogle = async (
    payload,
    valuesData,
    targetLanguage
  ) => {
    let data = [
      payload.name.en,
      payload.description.en,
      payload.meta_description.en,
      payload.meta_keywords.en,
      payload.meta_title.en,
    ];
    await translateByGoogleApi(data, targetLanguage).then((res) => {
      valuesData.set("name", res[0]?.translatedText);
      valuesData.set("description", res[1]?.translatedText);
      valuesData.set("abbreviation", payload.abbreviation);
      valuesData.set("language", targetLanguage);
      valuesData.append("meta_title", "");
      valuesData.append("meta_description", "");
      valuesData.append("meta_keywords", "");
      api.post(`admin/update-category/${payload.id}`, valuesData).then(() => {
        navigate(`/categories`);
      });
    });
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      {!loadingData ? (
        <div className="CategoryDetails">
          <h1 className={tabvalue != "ar" ? "" : " arabic-component"}>
            {create ? "New Category" : getValues("display_name")}
          </h1>

          <TabContext value={tabvalue}>
            <Tabs
              value={tabvalue}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              {enabledLanguages.map((item, index) =>
                create && item.code === "en" ? (
                  <CustomTab
                    index={index}
                    value={item.code}
                    label={item.name}
                    icon={<USAFlagIcon />}
                  />
                ) : (
                  !create && (
                    <CustomTab
                      index={index}
                      value={item.code}
                      label={item.name}
                      icon={
                        item.code == "en" ? (
                          <USAFlagIcon />
                        ) : item.code == "ar" ? (
                          <img src={KSAFlag} width="30" />
                        ) : (
                          ""
                        )
                      }
                    />
                  )
                )
              )}
            </Tabs>
            <form
              onSubmit={handleSubmit(handleSubmitForm)}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              className={tabvalue != "ar" ? "" : ""}
            >
              <div className="category-detail-component">
                <div className="form-section">
                  <Card>
                    <CardContent>
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <FormInput
                            required
                            label="Category"
                            placeholder="Enter category name"
                            name="name"
                            id="name"
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            helperText={error?.message}
                          />
                        )}
                        name="name"
                        control={control}
                      />
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <FormInput
                            label="Description"
                            placeholder="Enter description"
                            name="description"
                            id="description"
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            helperText={error?.message}
                          />
                        )}
                        name="description"
                        control={control}
                      />

                      {tabvalue != "ar" && (
                        <Controller
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <FormInput
                              label="Abbreviation"
                              placeholder="Enter Abbreviation"
                              name="abbreviation"
                              id="abbreviation"
                              value={value}
                              onChange={(e) => onChange(e.target.value)}
                              helperText={error?.message}
                            />
                          )}
                          name="abbreviation"
                          control={control}
                        />
                      )}

                      {tabvalue != "ar" && (
                        <>
                          <Controller
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <FormMultiSelect
                                label="Item identity"
                                placeholder="Enter item identity"
                                name="item_identity_id"
                                id="item_identity_id"
                                value={value}
                                isArabic={tabvalue != "ar" ? false : true}
                                arabicPlaceholder=""
                                data={itemIdentities?.data?.data || []}
                                loading={loadingIdentities}
                                onChange={(e) => onChange(e)}
                                helperText={error?.message}
                              />
                            )}
                            name="item_identity_id"
                            control={control}
                          />

                          <div className="switches-type-group">
                            <InputLabel>Type</InputLabel>
                            <div className="switchGroup">
                              <Controller
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <FormSwitch
                                    label="Buy"
                                    name="buy"
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e?.target?.checked);
                                      if (e?.target?.checked) {
                                        setValue("active", true, {
                                          shouldValidate: true,
                                        });
                                      } else {
                                        if (getValues("sell") === false) {
                                          setValue("active", false, {
                                            shouldValidate: true,
                                          });
                                        }
                                      }
                                    }}
                                  />
                                )}
                                name="buy"
                                control={control}
                              />
                              <Controller
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <FormSwitch
                                    label="Sell"
                                    name="sell"
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e?.target?.checked);
                                      if (e?.target?.checked) {
                                        setValue("active", true, {
                                          shouldValidate: true,
                                        });
                                      } else {
                                        if (getValues("buy") === false) {
                                          setValue("active", false, {
                                            shouldValidate: true,
                                          });
                                        }
                                      }
                                    }}
                                  />
                                )}
                                name="sell"
                                control={control}
                              />
                              <Controller
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <FormSwitch
                                    label="Picea"
                                    name="picea_enabled"
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e?.target?.checked);
                                    }}
                                  />
                                )}
                                name="picea_enabled"
                                control={control}
                              />
                            </div>
                            {errors?.type_message?.message !== "" && (
                              <FormHelperText>
                                {errors?.type_message?.message}
                              </FormHelperText>
                            )}
                          </div>
                          <div className="switches-status-group">
                            <InputLabel>Status</InputLabel>

                            <div className="switchGroup">
                              <Controller
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <FormSwitch
                                    label="Active"
                                    name="active"
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e?.target?.checked);
                                      if (!e?.target?.checked) {
                                        setValue("buy", false, {
                                          shouldValidate: true,
                                        });
                                        setValue("sell", false, {
                                          shouldValidate: true,
                                        });
                                      }
                                    }}
                                  />
                                )}
                                name="active"
                                control={control}
                              />{" "}
                              <Controller
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <FormSwitch
                                    label="Coming Soon"
                                    name="coming_soon"
                                    value={value}
                                    onChange={(e) =>
                                      onChange(e?.target?.checked)
                                    }
                                  />
                                )}
                                name="coming_soon"
                                control={control}
                              />
                            </div>
                          </div>

                          <div className="mb-2">
                            <h6>Upload Image</h6>

                            {tabvalue != "ar" && (
                              <Controller
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <UploadComponent
                                    value={value}
                                    setFieldValue={(field, value) =>
                                      onChange(value)
                                    }
                                    helperText={error?.message}
                                  />
                                )}
                                name="image"
                                control={control}
                              />
                            )}
                          </div>
                        </>
                      )}
                    </CardContent>
                  </Card>
                </div>

                <div className="upload-section">
                <Card>
                <CardContent>
                  <MetaFieldsForm
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    clearErrors={clearErrors}
                  />
                  </CardContent>
                  </Card>
                  <div className="action-footer my-2 ">
                    <Button
                      name={create ? "Create" : "Save"}
                      selected
                      loading={saveLoading}
                      disabled={!create && loadingData}
                      type="submit"
                      width={"40%"}
                    />
                  </div>
                </div>
              </div>
            </form>
          </TabContext>
        </div>
      ) : (
        <div className="loaderClass">
          <CircularProgress />
        </div>
      )}
    </>
  );
}
export default CategoryHandle;
