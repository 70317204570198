import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { FormTextArea, FormInput, FormMultiSelect } from "./FormComponents";

const MetaFieldsForm = ({ control, setValue, getValues, clearErrors }) => {
  const [keywordsLengthError, setKeywordsLengthError] = useState("");

  return (
    <div>
      <div sx={{ boxShadow: "none" }}>
        {/* ✅ Meta Title */}
        <Controller
          name="metatitle"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormInput
              required
              hideVariant
              name="metatitle"
              id="metatitle"
              label="Meta Title"
              placeholder="Enter Meta Title (Max 60 characters)"
              {...field}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />

        {/* ✅ Meta Description */}
        <Controller
          name="metadescription"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormTextArea
              required
              rows="3"
              name="metadescription"
              id="metadescription"
              label="Meta Description"
              placeholder="Enter Meta Description (Max 160 characters)"
              {...field}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />

        {/* ✅ Meta Tags (MultiSelect) */}
        <Controller
          name="metakeywords"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormMultiSelect
              required
              label="Meta Tags"
              placeholder="Enter Meta Tags (Max 10 keywords, 255 characters total)"
              isfreeSolo
              data={[]} // Available tag options
              hideVariant
              name="metakeywords"
              id="metakeywords"
              accessValue="tag_name"
              value={field.value || []}
              onChange={(selectedTags) => {
                if (!selectedTags) return;

                const formattedTags = selectedTags.map((tag) => ({
                  id: tag.id || tag.tag_name,
                  tag_name: tag.tag_name || tag.id,
                }));

                const tagsString = formattedTags
                  .map((tag) => tag.tag_name)
                  .join(", ");

                // ✅ Check if total character length exceeds 255
                if (tagsString.length > 255) {
                  setKeywordsLengthError(
                    "Total length of tags must not exceed 255 characters"
                  );
                  return;
                } else {
                  setKeywordsLengthError("");
                }

                // ✅ Clear validation errors if the value is now valid
                if (formattedTags.length > 0 && formattedTags.length <= 10) {
                  clearErrors("metakeywords");
                }

                setValue("metakeywords", formattedTags, {
                  shouldValidate: true,
                });
              }}
              error={Boolean(error) || Boolean(keywordsLengthError)}
              helperText={keywordsLengthError || error?.message}
            />
          )}
        />
      </div>
    </div>
  );
};

export default MetaFieldsForm;
