import React, { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AlertContext } from "../../../context/AlertContext";
import * as yup from "yup";
import { useGet } from "../../../hooks/useFetch";
import { useGlobalValues } from "../../../context/GlobalContext";
import Button from "../../../components/button/Button";
import MetaFieldsForm from "../../../components/formComponents/metadynamicform";
import { updateSectionone } from "../../../core/apis/store";
import { findValue } from "../../../core/functions/Functions";
import { Skeleton } from "@mui/material";

const CommonMetaDetails = (props) => {
  const { metatype } = props;
  const Schema = yup.object({
    metatitle: yup
      .string()
      .required("Meta title is required")
      .max(60, "Meta title must be at most 60 characters"),
    metadescription: yup
      .string()
      .required("Meta description is required")
      .max(160, "Meta description must be at most 160 characters"),
    metakeywords: yup
      .array()
      .min(1, "At least one keyword is required")
      .max(10, "You can add up to 10 keywords only"), // ✅ Limit to 10 items
  });

  const { storeLanguage } = useGlobalValues();
  const [loading, setLoading] = useState(false);
  const [fetchingdata, setfetchingdata] = useState(false);
  const { setAlert } = useContext(AlertContext);

  // // Get Details by Document Types
  const {
    data: detailsbyTypes,
    loading: loadingAllMetatypes,
    refetch,
  } = useGet({
    url: `admin/section/${metatype}`,
  });

  let detailsbyTypesArray = detailsbyTypes?.data?.data;

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(Schema) });

  // Fetch data and populate form if ID is provided
  useEffect(() => {
    if (detailsbyTypesArray) {
      setValue(
        "metatitle",
        findValue("meta_title", detailsbyTypesArray?.columns, storeLanguage)
      );

      setValue(
        "metadescription",
        findValue(
          "meta_description",
          detailsbyTypesArray?.columns,
          storeLanguage
        )
      );

      const keywords = findValue(
        "meta_keywords",
        detailsbyTypesArray?.columns,
        storeLanguage
      );

      setValue(
        "metakeywords",
        keywords != ""
          ? keywords.split(",").map((item, index) => ({
              tag_name: item.trim(),
              id: item.trim(), // Assigning a unique ID (you can modify it as needed)
            }))
          : []
      );
      setfetchingdata(false);
    }
  }, [detailsbyTypesArray]);

  // Fetch data and populate form if ID is provided
  useEffect(() => {
    setfetchingdata(true);
    refetch();
  }, [storeLanguage]);

  const handleSubmitForm = async (values) => {
    setLoading(true);
    let metaTags = values.metakeywords.map((tag) => tag.tag_name).join(", ");
    let formData = new FormData();
    formData.append("language", storeLanguage);
    formData.append("meta_title", values.metatitle);
    formData.append("meta_description", values.metadescription);
    formData.append("meta_keywords", metaTags);

    updateSectionone(metatype, formData).then((res) => {
      setAlert({
        visible: true,
        text: res?.data.success ? "Successfully Updated" : res?.data?.message,
        type: res?.data?.success ? "success" : "error",
      });
      refetch();
      setLoading(false);
    });
  };
  const handleCancel = () => {
    setfetchingdata(true);
    refetch();
  };

  if (loadingAllMetatypes || fetchingdata) {
    return (
      <div className="my-4">
        <div className="form-section">
          <Skeleton variant="text" width="100%" height={50} />
          <Skeleton variant="text" width="100%" height={50} />
          <Skeleton variant="text" width="100%" height={50} />
          <Skeleton variant="text" width="100%" height={50} />
          <Skeleton variant="text" width="100%" height={50} />
        </div>
      </div>
    );
  }

  return (
    <div className="AboutMetaDetails my-4">
      <div className="form-section">
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <MetaFieldsForm
            control={control}
            setValue={setValue}
            getValues={getValues}
            clearErrors={clearErrors}
          />
          <div className="action-footer">
            <Button
              selected
              name={"Save"}
              type="submit"
              loading={loading}
              className="mx-1"
            />
            <Button
              onClick={() => handleCancel()}
              name={"Cancel"}
              type="button"
              className="mx-1"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CommonMetaDetails;
