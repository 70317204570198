// EXTERNAL LIBRARIES
import React, { useState, useEffect, useContext } from "react";
import { Card, Paper } from "@mui/material";

import { useForm, Controller } from "react-hook-form";
// STYLING
import "../../brand-product-type/products/productDetails/ProductDetails.scss";
import "../../productSKU/editproductskudetails/editproductskudetails.scss";

// CUSTOM HOOKS
import { api } from "../../../core/apis/main";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  FormInput,
  FormDropdownList,
  FormMultiSelect,
  FormDropdownListloader,
} from "../../../components/formComponents/FormComponents";
import { CountryContext } from "../../../context/CountryContext";
import { useGlobalValues } from "../../../context/GlobalContext";
import TitleComponent from "../../../components/titleComponent/TitleComponent";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import { useParams } from "react-router-dom";
import { AlertContext } from "../../../context/AlertContext";
import { createProductSku } from "../../../core/apis/item";
import NewEditor from "../../../components/editor/NewEditor";
import Button from "../../../components/button/Button";
import { LiveStatues } from "../../../core/constants/constant";
import { useGet } from "../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import MetaFieldsForm from "../../../components/formComponents/metadynamicform"

// Formik schema

const HandleSchema = yup.object({
  title: yup.string("").required("Field is Required"),
  description: yup.string("Enter a description").nullable(),
  sell_price: yup.string("").required("Field is Required"),
  condition_id: yup.object().required(""),
  product_id: yup.object().required(""),
  metatitle: yup
    .string()
    .required("Meta title is required")
    .max(60, "Meta title must be at most 60 characters"),
  metadescription: yup
    .string()
    .required("Meta description is required")
    .max(160, "Meta description must be at most 160 characters"),
  metakeywords: yup
    .array()
    .min(1, "At least one keyword is required")
    .max(10, "You can add up to 10 keywords only"), // ✅ Limit to 10 items
});

const AddProductSku = () => {
  const { setAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [tabvalue, setTabValue] = useState("en");
  const { enabledLanguages } = useGlobalValues();
  const [attributeList, setAttributeList] = useState([]);
  let navigate = useNavigate();

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });

  const { data: conditions, conditionsLoading } = useGet({
    url: "/user/get-all-conditions",
  });

  let payload = {
    per_page: 10,
    page: 1,
  };

  let url = `/admin/get-all-products`;
  // API CALL
  const { data: productSKU, loadingProducts } = useGet({
    url: url,
    payload: payload,
  });

  let Productdata = productSKU?.data?.data?.data;

  const {
    data: attributes,
    attributesLoading,
    refetch,
  } = useGet({
    url: `admin/get-all-attributes`,
  });

  const handleChange = (event) => {
    setTabValue(event);
  };

  const returnValueOptions = (id) => {
    const Value = attributes?.data?.data?.filter((item) => item?.id == id);
    return Value?.length > 0 ? Value[0]?.attribute_value : [];
  };

  const handleSubmitForm = (values) => {
    setLoading(true);
    let data = values?.attribute_values_estimation?.map((item) => item.id);

    let metaTags = values.metakeywords.map((tag) => tag.tag_name).join(", ");

    let payload = {
      title: values?.title,
      description: values?.description,
      sell_price: values?.sell_price,
      active: values.active.value,
      language: tabvalue,
      attribute_value_ids: data,
      condition_id: values.condition_id.id,
      product_id: values.product_id.id,
      meta_title: values.metatitle,
      meta_description: values.metadescription,
      meta_keywords: metaTags,
    };

    createProductSku(payload).then((res) => {
      if (res?.data) {
        setAlert({
          visible: true,
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
        setLoading(false);
      }
      if (res?.data?.success) {
        navigate("/product-sku");
      }
    });
  };

  return (
    <div className="products-details">
      {!loading ? (
        <Paper elevation={24} className="paper-div">
          <div className="header">
            <TitleComponent
              className="title-details"
              title={"Add New Product"}
            />
            <div className="chip-wrapper">
              {enabledLanguages.map((item, index) => (
                <Chip
                  key={index}
                  label={item.name}
                  value={item.code}
                  className={
                    tabvalue != item.code ? "en-chip" : "en-chip color-white"
                  }
                  color="primary"
                  variant={tabvalue != item.code ? "outlined" : ""}
                  onClick={() => handleChange(item.code)}
                />
              ))}
            </div>
          </div>

          <Card variant="elevation">
            <Box sx={{ p: 2 }}>
              <div className="editskuDetails">
                <form onSubmit={handleSubmit(handleSubmitForm)}>
                  <div
                    className={
                      tabvalue == "ar"
                        ? "form-section arabic-component"
                        : "form-section"
                    }
                  >
                    <div className="itemFlexRow gap-4">
                      <div className="flex60COL">
                        <Controller
                          render={({ field, fieldState: { error } }) => (
                            <FormInput
                              className="formInput1"
                              label=" Title"
                              name="title"
                              hideVariant={true}
                              type="text"
                              placeholder="Enter Title"
                              value={field.value}
                              onChange={(e) => field.onChange(e.target.value)}
                              helperText={error?.message}
                            />
                          )}
                          name="title"
                          control={control}
                        />

                        <Controller
                          render={({ field, fieldState: { error } }) => (
                            <FormInput
                              className="formInput1"
                              label="Sell Price"
                              name="sell_price"
                              hideVariant={true}
                              type="number"
                              placeholder="Enter Sell price"
                              value={field.value}
                              onChange={(e) => field.onChange(e.target.value)}
                              helperText={error?.message}
                            />
                          )}
                          name="sell_price"
                          control={control}
                        />

                        <Controller
                          name="product_id"
                          control={control}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <FormDropdownListloader
                              label="Select Product"
                              name="product_id"
                              id="product_id"
                              perpage="10"
                              placeholder="Select Product"
                              data={Productdata || []}
                              loading={loadingProducts}
                              hideVariant
                              value={value || ""}
                              onChange={(e) => onChange(e)}
                              accessValue="name"
                              loadMoreUrl="admin/get-all-products"
                            />
                          )}
                        />

                        <Controller
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <FormMultiSelect
                              required
                              data={attributes?.data?.data || []}
                              loading={attributesLoading}
                              label="Attribute"
                              name="attribute_id"
                              clearErrors={clearErrors}
                              dependencyError={["attribute_id"]}
                              placeholder={"Select attribute"}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                setAttributeList(e);
                              }}
                              helperText={error?.message}
                              hideVariant={true}
                            />
                          )}
                          name="attribute_id"
                          control={control}
                        />
                        {attributeList &&
                          attributeList

                            ?.sort((a, b) => a.id - b.id)
                            .map((attValues, index) => (
                              <Controller
                                key={attValues.id}
                                control={control}
                                name={`attribute_values_estimation.${index}`}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <FormDropdownList
                                    required
                                    data={
                                      returnValueOptions(attValues.id) || []
                                    }
                                    loading={attributesLoading}
                                    label={`${attValues?.name?.en} Attribute Values`}
                                    name={`attribute_values_estimation.${index}`}
                                    clearErrors={clearErrors}
                                    dependencyError={[
                                      `attribute_values_estimation.${index}`,
                                    ]}
                                    placeholder="Select Attribute Values"
                                    value={value}
                                    onChange={(selectedValues) => {
                                      onChange(selectedValues);
                                    }}
                                    helperText={error?.message}
                                    hideVariant={true}
                                  />
                                )}
                              />
                            ))}

                        <Controller
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <FormDropdownList
                              required
                              data={conditions?.data?.data || []}
                              loading={conditionsLoading}
                              label="Condition"
                              name="condition_id"
                              hideVariant={true}
                              placeholder={"Select condition"}
                              value={value}
                              onChange={(value) => onChange(value)}
                              helperText={error?.message}
                            />
                          )}
                          name="condition_id"
                          control={control}
                        />

                        <Controller
                          name="active"
                          control={control}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <FormDropdownList
                              data={LiveStatues || []}
                              label="Product Status"
                              name="active"
                              placeholder={"Select Product Status"}
                              value={value || ""}
                              hideVariant
                              onChange={(e) => {
                                onChange(e);
                              }}
                              helperText={error?.message}
                            />
                          )}
                        />
                        <Controller
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <NewEditor
                              label={"Description"}
                              value={value}
                              lang={tabvalue}
                              onChange={(e) => onChange(e)}
                            />
                          )}
                          name="description"
                          control={control}
                        />
                      </div>
                      <div className="flex60COL">
                        <MetaFieldsForm
                          control={control}
                          setValue={setValue}
                          getValues={getValues}
                          clearErrors={clearErrors}
                        />
                      </div>
                    </div>
                    <div className="action-footer">
                      <Button type="button" name="Cancel" />
                      <Button
                        className="save-button"
                        type="submit"
                        name="Save"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </Box>
          </Card>
        </Paper>
      ) : (
        <Paper elevation={24} className="paper-div">
          <Card variant="elevation">
            <Box sx={{ p: 2 }}>
              <Typography gutterBottom variant="h6">
                <Skeleton variant="rectangular" width={100} height={20} />
              </Typography>
              <Stack direction="row" spacing={1} sx={{ py: 2 }}>
                <Skeleton variant="rounded" width={150} height={20} />
                <Skeleton variant="rounded" width={150} height={20} />
                <Skeleton variant="rounded" width={150} height={20} />
              </Stack>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
            </Box>
          </Card>
        </Paper>
      )}
    </div>
  );
};

export default AddProductSku;
