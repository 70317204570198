// EXTERNAL LIBRARIES
import "./itemdetailsStyle.scss";
import { UploadSVG, DownSVG } from "../../assests/icons/SVG";
import * as yup from "yup";
import { useParams, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState, useEffect, useContext } from "react";
import { FormInput } from "../../components/formComponents/FormComponents";
import { api } from "../../core/apis/main";
import MultipleCropper from "../../components/singleUpload/multipleImageCropper";
import TitleComponent from "../../components/titleComponent/TitleComponent";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Paper,
  ListItem,
  ListItemText,
  Skeleton,
  Card,
} from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import NewEditor from "../../components/editor/NewEditor";
import Button from "../../components/button/Button";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { useGlobalValues } from "../../context/GlobalContext";
import BrowseGalleryIcon from "@mui/icons-material/BrowseGallery";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import Cropper from "../../components/singleUpload/imageCropper";
import Chip from "@mui/material/Chip";
import moment from "moment";
import { updateItem } from "../../core/apis/item";

import {
  delteItemImages,
  uploadFeaturedItemImage,
  uploadItemImages,
} from "../../core/apis/item";
import { AlertContext } from "../../context/AlertContext";

const ItemNewDetails = (props) => {
  const { create } = props;

  const HandleSchema = yup.object({
    custom_name: yup.string().nullable(),
    buyer_price: yup
      .number("Invalid Field")
      .nullable()
      .required("Field is required"),
    offered_price: yup
      .number("Invalid Field")
      .nullable()
      .required("Field is required"),
    description: yup.string().nullable(),
    warranty: yup.string().nullable(),
    qr_link: yup.string().nullable().url("Must be a valid URL"),
    identity: yup.array().nullable(),
    item_identities: yup.array().of(
      yup.object().shape({
        item_identity_id: yup.number().nullable(),
        value: yup.string("Field is invalid").required("Field is required"),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });

  const params = useParams();
  const { setAlert } = useContext(AlertContext);

  const [existingImages, setExistingImages] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  const [uploadFeatured, setuploadFeaturedImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [nextAction, setnextAction] = useState([]);
  const { enabledLanguages } = useGlobalValues();
  const [tabvalue, setTabValue] = useState("en");
  const [imageVal, setImageValue] = useState(null);
  const [tabdetailsValue, setTabDetailsValue] = useState("0");
  const [itemIdentity, setitemIdentityList] = useState("");

  let state = useLocation()?.state;

  const getData = async () => {
    setLoading(true);
    return await api.get(`/admin/get-item-by-id/${params.id}`).then((res) => {
      setData(res?.data?.data?.item_details);
      if (res?.data?.success) {
        let details = res?.data?.data?.item_details;
        setnextAction(res?.data?.data?.next_action);
        setExistingImages(
          details?.images?.filter((item) => !item?.is_featured)
        );
        let selectedInspection = details?.inspection?.filter(
          (item) => item?.is_selected
        );
        let categoryItemIdentity = details?.product?.category?.item_identity;
        let parentCategoryItemIdentity =
          details?.product?.category?.parent?.item_identity;

        // Combine arrays and remove duplicates based on `id`
        const combinedArray = [
          ...categoryItemIdentity,
          ...parentCategoryItemIdentity,
        ];
        const uniqueArray = combinedArray.reduce((acc, current) => {
          const x = acc.find((item) => item.id === current.id);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);

        setitemIdentityList(uniqueArray);

        let identityValue = details?.identity.map((item) => item?.pivot);

        reset({
          item_code: details?.item_code,
          product_id: params?.id,
          description: details?.description?.[tabvalue],
          custom_name: details.custom_name?.[tabvalue],
          offered_price: details?.offered_price,
          buyer_price: details?.buyer_price,
          cost: details?.item_cost,
          brand: details?.product?.brand?.name?.[tabvalue],
          category: details?.product?.category?.name?.[tabvalue],
          date: moment(details?.created_at).format("DD-MM-YYYY"),
          country: details?.country?.name,
          condition: "",
          featured_image: details?.featured_image,
          item_identities: identityValue,
          image_path: details?.images.filter((item) => item.is_featured),
          statusValue:
            res.data.data.next_action[0].id == 7
              ? "Under Maintenance"
              : res.data.data.next_action[0].id == 8
              ? "Un-Publish"
              : "Publish",
          qr_link: details?.qr_link,
          warranty: details?.warranty?.[tabvalue],
          language: tabvalue,
        });
      }
      setLoading(false);
    });
  };

  // On mount of the component get the data corresponding to the selected product sku
  useEffect(() => {
    if (!create) {
      getData();
    } else {
      reset({
        item_code: null,
        product_id: null,
        description: "",
        offered_price: null,
        buyer_price: null,
        warranty: "",
        qr_link: null,
        custom_name: null,
        cost: null,
        brand: null,
        category: null,
        date: null,
        country: null,
        condition: null,
        featured_image: null,
        item_identities: [],
        image_path: [],
        statusValue: null,
        language: tabvalue,
      });
    }
  }, [tabvalue]);

  const handleSubmitForm = (values) => {
    let payload = {
      id: params?.id,
      custom_name: values?.custom_name,
      description: values?.description,
      offered_price: values?.offered_price,
      buyer_price: values?.buyer_price,
      warranty: values?.warranty,
      qr_link: values?.qr_link,
      language: tabvalue,
      item_identities: values?.item_identities,
    };

    if (fileList?.length > 0) {
      handleuploadImages();
    }
    updateItem(payload).then((res) => {
      if (res.data.success) {
        getData();
      }
      setAlert({
        visible: true,
        text: res.data.message,
        type: res.data.success ? "success" : "error",
      });
    });
  };

  // Removing file from list state (currently selected by user)
  const removeFile = (file) => {
    let filtered = fileList.filter(function (e) {
      return e != file;
    });
    setFileList(filtered);
    setValue("imageList", filtered);
  };

  // Setting the files to be deleted from the existing files (fetched data for edit)
  const removeImage = (id) => {
    deletedImages.push(id);
    setDeletedImages(deletedImages);
    let filtered = existingImages.filter(function (e) {
      return e.id != id;
    });
    setExistingImages(filtered);
  };

  // HandleImages
  const handleImages = (imagesList) => {
    let existing = fileList ? [...fileList] : [];
    existing.push(imagesList);
    setFileList(existing);
    setValue("imageList", existing);
  };

  const fields = [
    { name: "item_code", label: "Item ID" },
    { name: "cost", label: "Cost" },
    { name: "brand", label: "Brand" },

    { name: "statusValue", label: "Status" },
    { name: "country", label: "Location" },
    { name: "category", label: "Category" },

    { name: "date", label: "Date" },
    { name: "condition", label: "Condition" },
  ];

  const chunkSize = 3;
  const chunkedFields = [];
  for (let i = 0; i < fields.length; i += chunkSize) {
    chunkedFields.push(fields.slice(i, i + chunkSize));
  }

  const handleImage = (croppedImage) => {
    setImageValue(croppedImage);
    setValue("featured_image", croppedImage);
    handleuploadFeaturedImage(croppedImage);
  };

  const handleChange = (event, newValue) => {
    setTabDetailsValue(newValue);
  };

  const handleuploadFeaturedImage = (croppedImage) => {
    setuploadFeaturedImage(true);
    let formData = new FormData();
    formData.append("item_id", params.id);
    formData.append("featured_image", croppedImage);
    uploadFeaturedItemImage(formData).then((res) => {
      setAlert({
        visible: true,
        text: res.data.message,
        type: res.data.success ? "success" : "error",
      });
      setuploadFeaturedImage(false);
    });
  };

  const handleuploadImages = () => {
    let formData = new FormData();
    formData.append("item_id", params.id);
    fileList.map((image) => {
      formData.append("images[]", image);
    });
    uploadItemImages(formData).then((res) => {
      setAlert({
        visible: true,
        text: res.data.message,
        type: res.data.success ? "success" : "error",
      });
      setFileList([]);
    });
  };

  //delete selected images
  const handleDeleteImage = (images) => {
    let payload = {
      images_id: images,
    };
    delteItemImages(params.id, payload).then((res) => {
      setAlert({
        visible: true,
        text: res.data.message,
        type: res.data.success ? "success" : "error",
      });
    });
  };

  return (
    <div className="newProductSku">
      {!loading ? (
        <>
          <div className="header-detail">
            <TitleComponent
              className="title-details"
              title={create ? "New Product" : getValues("item_code") || ""}
            />
            <div className="chip-wrapper">
              {enabledLanguages.map((item, index) =>
                create && item.code === "en" ? (
                  <Chip
                    label={item.name}
                    value={item.code}
                    className="en-chip color-white"
                    color="primary"
                    key={index}
                  />
                ) : (
                  !create && (
                    <Chip
                      key={index}
                      label={item.name}
                      value={item.code}
                      className={
                        tabvalue != item.code
                          ? "en-chip"
                          : "en-chip color-white"
                      }
                      color="primary"
                      variant={tabvalue != item.code ? "outlined" : ""}
                      onClick={() => setTabValue(item.code)}
                    />
                  )
                )
              )}
            </div>
          </div>
          <div className="item-detail-container">
            <div className="item-detail">
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {chunkedFields.map((chunk, index) => (
                  <Grid item xs={12} sm={4} md={4} key={index}>
                    {chunk.map((field) => (
                      <ListItem key={field.name}>
                        <ListItemText
                          primary={field.label}
                          secondary={
                            field.label == "Cost"
                              ? getValues(field.name) + " " + "AED"
                              : field.label == "Condition"
                              ? state?.skuCondition
                              : getValues(field.name)
                          }
                        />
                      </ListItem>
                    ))}
                  </Grid>
                ))}
              </Grid>
            </div>

            <Cropper
              sendCroppedFile={handleImage}
              imageSrc={getValues("image_path[0].image_path")}
              MAX_IMAGE_WIDTH={500}
              MAX_IMAGE_HEIGHT={500}
              title={"Upload Featured Image 500*500"}
              imgClassName={"imgwidth200"}
              isTrue={true}
              isloading={uploadFeatured}
              iwebp={true}
            />
          </div>

          <Paper elevation={24} className="paper-div">
            <TabContext value={tabdetailsValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab
                    label="Item Details"
                    value="0"
                    icon={<ListAltIcon />}
                    iconPosition="start"
                  />
                  <Tab
                    label="Item Inspections History"
                    value="1"
                    icon={<BrowseGalleryIcon />}
                    iconPosition="start"
                  />
                  <Tab
                    label="Item Cost History"
                    value="2"
                    icon={<MonetizationOnIcon />}
                    iconPosition="start"
                  />
                  <Tab
                    label="Item Actions History"
                    value="3"
                    icon={<PendingActionsIcon />}
                    iconPosition="start"
                  />
                </TabList>
              </Box>
              <TabPanel value="0">
                <form onSubmit={handleSubmit(handleSubmitForm)}>
                  <div
                    className={
                      tabvalue == "ar"
                        ? "form-section arabic-component"
                        : "form-section"
                    }
                  >
                    <div className="itemFlexRow">
                      <div className="flex60COL">
                        <Controller
                          render={({ field, fieldState: { error } }) => (
                            <FormInput
                              className="formInput1"
                              label="Custom Name"
                              name="custom_name"
                              hideVariant={true}
                              type="text"
                              placeholder="Enter Item Name"
                              value={field.value}
                              onChange={(e) => field.onChange(e.target.value)}
                              helperText={error?.message}
                            />
                          )}
                          name="custom_name"
                          control={control}
                        />
                        {tabvalue == "en" && (
                          <>
                            <Controller
                              render={({ field, fieldState: { error } }) => (
                                <FormInput
                                  className="formInput1"
                                  label="Offered Price"
                                  name="offered_price"
                                  hideVariant={true}
                                  type="number"
                                  placeholder="Enter offered price"
                                  value={field.value}
                                  onChange={(e) =>
                                    field.onChange(e.target.value)
                                  }
                                  helperText={error?.message}
                                />
                              )}
                              name="offered_price"
                              control={control}
                            />
                            <Controller
                              render={({ field, fieldState: { error } }) => (
                                <FormInput
                                  className="formInput1"
                                  label="Buyer Price"
                                  name="buyer_price"
                                  hideVariant={true}
                                  type="number"
                                  placeholder="Enter buyer price"
                                  value={field.value}
                                  onChange={(e) =>
                                    field.onChange(e.target.value)
                                  }
                                  helperText={error?.message}
                                />
                              )}
                              name="buyer_price"
                              control={control}
                            />
                            {itemIdentity &&
                              itemIdentity?.map((item, index) => (
                                <div>
                                  <Controller
                                    render={({ field }) => (
                                      <input
                                        type="hidden"
                                        value={item.id}
                                        {...field}
                                      />
                                    )}
                                    name={`item_identities.${index}.item_identity_id`}
                                    control={control}
                                    defaultValue={item.id}
                                  />

                                  <Controller
                                    key={item.id}
                                    render={({
                                      field,
                                      fieldState: { error },
                                    }) => (
                                      <FormInput
                                        className="formInput1"
                                        label={item?.name?.en}
                                        name={`item_identities.${index}.value`}
                                        hideVariant={true}
                                        placeholder={
                                          "Enter" + " " + item?.name?.en
                                        }
                                        value={field.value}
                                        onChange={(e) =>
                                          field.onChange(e.target.value)
                                        }
                                        helperText={error?.message}
                                      />
                                    )}
                                    name={`item_identities.${index}.value`}
                                    control={control}
                                  />
                                </div>
                              ))}
                            <Controller
                              render={({ field, fieldState: { error } }) => (
                                <FormInput
                                  className="formInput1"
                                  label="QR LINK"
                                  name="qr_link"
                                  hideVariant={true}
                                  placeholder="Enter QR link"
                                  value={field.value}
                                  onChange={(e) =>
                                    field.onChange(e.target.value)
                                  }
                                  helperText={error?.message}
                                />
                              )}
                              name="qr_link"
                              control={control}
                            />
                          </>
                        )}
                        <Controller
                          render={({ field, fieldState: { error } }) => (
                            <FormInput
                              label="Warranty"
                              name="warranty"
                              hideVariant={true}
                              placeholder="Enter warranty"
                              value={field.value}
                              onChange={(e) => field.onChange(e.target.value)}
                              helperText={error?.message}
                            />
                          )}
                          name="warranty"
                          control={control}
                        />

                        <Controller
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <NewEditor
                              label={"Description"}
                              value={value}
                              lang={tabvalue}
                              onChange={(e) => onChange(e)}
                            />
                          )}
                          name="description"
                          control={control}
                        />
                      </div>
                      {tabvalue == "en" && (
                        <div className="flex40COL">
                          <div className="upload-body">
                            <div className="filesSection">
                              <Controller
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <MultipleCropper
                                    sendCroppedFile={handleImages}
                                    MAX_IMAGE_WIDTH={500}
                                    MAX_IMAGE_HEIGHT={500}
                                    sizeMsg={"500*500"}
                                    message={error?.message}
                                    error={error}
                                    className={"margin0"}
                                    iwebp={true}
                                  />
                                )}
                                name="imageList"
                                control={control}
                              />
                              <div className="files">
                                <ul className="fileList">
                                  {existingImages &&
                                    existingImages.map((image) => (
                                      <li className="image" key={image.id}>
                                        <img
                                          src={
                                            process.env.REACT_APP_IMAGE_URL +
                                            image.image_path
                                          }
                                        />
                                        <button
                                          type="button"
                                          onClick={(e) => {
                                            removeImage(image.id);
                                            handleDeleteImage(image.id)
                                            e.preventDefault();
                                          }}
                                        >
                                          x
                                        </button>
                                      </li>
                                    ))}
                                </ul>
                                <ul className="fileList">
                                  {fileList &&
                                    fileList.map((file, i) => (
                                      <li className="image" key={i}>
                                        <img src={URL.createObjectURL(file)} />
                                        <button
                                          type="button"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            removeFile(file);
                                          }}
                                        >
                                          x
                                        </button>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="action-footer">
                      <Button type="button" name="Cancel" />
                      <Button
                        className="save-button"
                        type="submit"
                        name="Save"
                      />
                    </div>
                  </div>
                </form>
              </TabPanel>
              <TabPanel value="1">
                <div className="inspections-summary">
                  <div className="inspections-list">
                    {data?.inspection?.map((el, index) => (
                      <Accordion className="inspection-accordion" key={index}>
                        <AccordionSummary
                          expandIcon={<DownSVG />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{ background: "#f0f8ff" }}
                        >
                          <div className="inspection-header">
                            <Typography>{`Inspection ${el?.id}`}</Typography>
                            <div>
                              {"Done by "}
                              {el?.is_admin_inspection ? "admin" : "user"}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="inspection-question-element">
                            <div className="inspection-element">
                              Inspection ID
                            </div>
                            <div className="inspection-element only-answer">
                              {el?.id}
                            </div>
                          </div>
                          <div className="inspection-question-element">
                            <div className="inspection-element">
                              Inspection Value
                            </div>
                            <div className="inspection-element only-answer">
                              {el?.inspection_value}
                            </div>
                          </div>
                          <div className="inspection-question-element">
                            <div className="inspection-element">Done by</div>
                            <div className="inspection-element only-answer">
                              {el?.is_admin_inspection ? "Admin" : "User"}
                            </div>
                          </div>
                          <div
                            className="inspection-question-element"
                            style={{ background: "#fff900" }}
                          >
                            <div className="inspection-element">Condition</div>
                            <div className="inspection-element only-answer">
                              {el?.condition?.name?.en}
                            </div>
                          </div>
                          <div className="inspection-question-element">
                            <div className="inspection-element">Product</div>
                            <div className="inspection-element only-answer">
                              {el?.item?.product?.name?.en}
                            </div>
                          </div>
                          {el?.attribute_value?.map((attributeElement) => (
                            <div className="inspection-question-element">
                              <div className="inspection-element">
                                {attributeElement?.attribute?.name?.en}
                              </div>
                              <div className="inspection-element only-answer">
                                {attributeElement?.name?.en}
                              </div>
                            </div>
                          ))}
                          {el?.question_answer.map((questionElement) => (
                            <div className="inspection-question-element">
                              <div className="inspection-element">
                                {questionElement?.question?.question?.en}
                              </div>
                              <div className="inspection-element only-answer">
                                {questionElement?.answer?.en}
                              </div>
                            </div>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="2">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Desc</TableCell>
                      <TableCell>Cost</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.item_costs_history.map((row) => (
                      <TableRow key={row.desc}>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>{row.value_amount}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell rowSpan={3} />
                      <TableCell
                        colSpan={2}
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          fontSize: "18px",
                        }}
                      >
                        Total
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          fontSize: "18px",
                        }}
                      >
                        {data?.item_cost + " " + "AED"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TabPanel>
              <TabPanel value="3" style={{overflow:"scroll"}}>
                <Table >
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Taken By</TableCell>
                      <TableCell>Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.action?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {row?.name == "Collect"
                            ? "Collected By"
                            : row?.name == "Sell"
                            ? "Bought By"
                            : row?.name}
                        </TableCell>
                        <TableCell>{row?.pivot?.action_taken_by}</TableCell>
                        <TableCell>
                          {moment(row?.pivot?.created_at).format("DD-MM-YYYY")}{" "}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TabPanel>
            </TabContext>
          </Paper>
        </>
      ) : (
        <Paper elevation={24} className="paper-div">
          <div className="item-detail-container" style={{ padding: "20px" }}>
            <div className="item-detail">
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {chunkedFields.map((chunk, index) => (
                  <Grid item xs={12} sm={4} md={4} key={index}>
                    {chunk.map((field) => (
                      <ListItem key={field.name}>
                        <ListItemText>
                          <Skeleton variant="rounded" width={80} height={20} />
                        </ListItemText>
                      </ListItem>
                    ))}
                  </Grid>
                ))}
              </Grid>
            </div>
            <div style={{ padding: "20px" }}>
              <Skeleton variant="rectangular" width={300} height={150} />
            </div>
          </div>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", padding: "20px" }}
          >
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
          </Box>
        </Paper>
      )}
    </div>
  );
};

export default ItemNewDetails;
