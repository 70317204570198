import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Grid, Skeleton } from "@mui/material";
import Button from "../../../components/button/Button";
import {
  FormInput,
  FormTextArea,
} from "../../../components/formComponents/FormComponents";
import { useGet } from "../../../hooks/useFetch";
import { useGlobalValues } from "../../../context/GlobalContext";
import { updateSectionone } from "../../../core/apis/store";
import { AlertContext } from "../../../context/AlertContext";
import Cropper from "../../../components/singleUpload/imageCropper";
import "./becomeaPartner.scss";
import MetaFieldsForm from "../../../components/formComponents/metadynamicform";

const InformationSection = () => {
  const [loading, setLoading] = useState(false);
  const [headerimageVal, setHeaderImageValue] = useState(null);
  const { setAlert } = useContext(AlertContext);
  const { storeLanguage } = useGlobalValues();
  const [isFetchingData, setIsFetchingData] = useState(true);

  // Schema validation
  const informationsectionSchema = yup.object({
    title: yup.string().nullable().required("Title is required"),
    paragraphnexttoimage: yup
      .string()
      .nullable()
      .required("Paragraph next to Image is required"),
    paragraphbelowimageparagraph1: yup.string().nullable(),
    headerImage: yup.mixed().nullable().required("Header image is required"),
    metatitle: yup
      .string()
      .required("Meta title is required")
      .max(60, "Meta title must be at most 60 characters"),
    metadescription: yup
      .string()
      .required("Meta description is required")
      .max(160, "Meta description must be at most 160 characters"),
    metakeywords: yup
      .array()
      .min(1, "At least one keyword is required")
      .max(10, "You can add up to 10 keywords only"), // ✅ Limit to 10 items
  });

  const handleCancel = () => {
    refetch();
  };

  const handleHeaderImage = (croppedImage) => {
    setHeaderImageValue(croppedImage);
    setValue("headerImage", croppedImage);
  };

  const defaults = {
    title: "",
    paragraph_next_to_image: "",
    paragraph_below_image_paragraph_1: "",
    headerImage: null,
  };

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(informationsectionSchema),
    defaultValues: defaults,
  });

  // Fetch section details by slug
  const {
    data: sectionDetails,
    loading: loadingDetails,
    refetch,
  } = useGet({
    url: "admin/section/BECOME_A_PARTNER_SECTION",
  });

  const informationSectionDetailsArray = sectionDetails?.data?.data;

  console.log(informationSectionDetailsArray, "informationSectionDetailsArray");

  useEffect(() => {
    if (informationSectionDetailsArray) {
      setIsFetchingData(true);

      const titleObject = informationSectionDetailsArray?.columns?.find(
        (item) => item.title && item.language === storeLanguage
      );
      const paragraphNextToImageObject =
        informationSectionDetailsArray?.columns?.find(
          (item) =>
            item.paragraph_next_to_image && item.language === storeLanguage
        );
      const paragraphBelowImageParagraph1Object =
        informationSectionDetailsArray?.columns?.find(
          (item) =>
            item.paragraph_below_image_paragraph_1 &&
            item.language === storeLanguage
        );
      const imageObject = informationSectionDetailsArray?.columns?.find(
        (item) => item.image && item.language === storeLanguage
      );

      const metatitle = informationSectionDetailsArray?.columns?.find(
        (item) => item.meta_title && item.language === storeLanguage
      );
      const metadescription = informationSectionDetailsArray?.columns?.find(
        (item) => item.meta_description && item.language === storeLanguage
      );
      const metakeywords = informationSectionDetailsArray?.columns?.find(
        (item) => item.meta_keywords && item.language === storeLanguage
      );

      console.log("metakeywords", metakeywords, metatitle);

      const populatedFormData = {
        title: titleObject?.title,
        paragraphnexttoimage:
          paragraphNextToImageObject?.paragraph_next_to_image,
        paragraphbelowimageparagraph1:
          paragraphBelowImageParagraph1Object?.paragraph_below_image_paragraph_1,
        headerImage: imageObject?.image,
        metatitle: metatitle?.meta_title || "",
        metadescription: metadescription?.meta_description  || "",
        metakeywords:
          metakeywords?.meta_keywords?.split(/,\s*/)?.map((item) => ({
            tag_name: item.trim(),
            id: item.trim(), // Assigning a unique ID
          })) || [],
      };

      // Setting form values using setValue
      Object.keys(populatedFormData).forEach((key) => {
        setValue(key, populatedFormData[key]);
      });

      setIsFetchingData(false);
    }
  }, [informationSectionDetailsArray, storeLanguage, setValue]);

  const handleSubmitForm = async (values) => {
    let formData = new FormData();
    let metaTags = values.metakeywords.map((tag) => tag.tag_name).join(", ");
    setLoading(true);
    formData.append("title", values.title);
    formData.append("paragraph_next_to_image", values.paragraphnexttoimage);
    formData.append(
      "paragraph_below_image_paragraph_1",
      values.paragraphbelowimageparagraph1
    );
    formData.append("image", values.headerImage);
    formData.append("language", storeLanguage);
    formData.append("meta_title", values.metatitle);
    formData.append("meta_description", values.metadescription);
    formData.append("meta_keywords", metaTags);

    if (values.headerImage instanceof File === false) {
      formData.delete("image");
    }

    updateSectionone("BECOME_A_PARTNER_SECTION", formData).then((res) => {
      setAlert({
        visible: true,
        text: res?.data.success ? "Successfully Updated" : res?.data?.message,
        type: res?.data?.success ? "success" : "error",
      });
      refetch();
      setLoading(false);
    });
  };

  return (
    <div className="informationSection">
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormInput
                      required
                      name="title"
                      label="Title for Partner Section"
                      hideVariant
                      placeholder={"Enter Title"}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      helperText={error?.message}
                    />
                  )}
                  name="title"
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormTextArea
                      hideVariant
                      rows={5}
                      name="paragraphnexttoimage"
                      label="Main Description Next to Image"
                      placeholder={"Enter Description"}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      helperText={error?.message}
                    />
                  )}
                  name="paragraphnexttoimage"
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormTextArea
                      hideVariant
                      name="paragraphbelowimageparagraph1"
                      label="Partners Summary (Optional)"
                      placeholder="Enter Description"
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      helperText={error?.message}
                    />
                  )}
                  name="paragraphbelowimageparagraph1"
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Grid item xs={12}>
              <MetaFieldsForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                clearErrors={clearErrors}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              {isFetchingData ? (
                <Skeleton variant="rectangular" width={100} height={40} />
              ) : (
                <Button
                  onClick={() => handleCancel()}
                  name={"Cancel"}
                  type="button"
                  className="mx-1"
                />
              )}
            </Grid>
            <Grid item>
              {isFetchingData ? (
                <Skeleton variant="rectangular" width={100} height={40} />
              ) : (
                <Button
                  selected
                  name={"Save"}
                  type="submit"
                  className="mx-1"
                  loading={loading}
                />
              )}
            </Grid>
          </Grid>

          {/* <Grid item xs={12} sm={4}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {isFetchingData ? (
                  <Skeleton variant="rectangular" width="100%" height={200} />
                ) : (
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Box>
                        <Cropper
                          required
                          sendCroppedFile={handleHeaderImage}
                          MAX_IMAGE_WIDTH={400}
                          MAX_IMAGE_HEIGHT={400}
                          title={"Drag your Header Image here only webp"}
                          className="width100 height100"
                          isTrue={true}
                          iwebp={true}
                          imageSrc={getValues("headerImage")}
                        />
                        {error && value == null ? (
                          <p className="error">{error.message}</p>
                        ) : null}
                      </Box>
                    )}
                    name="headerImage"
                    control={control}
                  />
                )}
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </form>
    </div>
  );
};

export default InformationSection;
