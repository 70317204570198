// EXTERNAL LIBRARIES
import React, { useState, useEffect, useContext } from "react";
import { Card, TableCell, Paper, Button } from "@mui/material";

import { useForm } from "react-hook-form";
// STYLING
import "../../brand-product-type/products/productDetails/ProductDetails.scss";

// CUSTOM HOOKS
import { useGet } from "../../../hooks/useFetch";
import { api } from "../../../core/apis/main";

import { CountryContext } from "../../../context/CountryContext";
import { useGlobalValues } from "../../../context/GlobalContext";
import TitleComponent from "../../../components/titleComponent/TitleComponent";
import TableRowComponent from "../../../components/table/tableBody/TableRowComponent";
import TableBodyComponent from "../../../components/table/tableBody/TableBodyComponent";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import WorkIcon from "@mui/icons-material/Work";
import CategoryIcon from "@mui/icons-material/Category";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Grid from "@mui/material/Grid";
import moment from "moment";
import Skeleton from "@mui/material/Skeleton";

import {
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import Barcode from "react-barcode";
import DataNotFound from "../../../components/notFound/DataNotFound";
import ProductStatusHandle from "../../product-item-sku/ProductStatusHandle";
import ProductItemHandle from "../../product-item-sku/ProductItemHandle";
import { getProductVariation } from "../../../core/apis/product";
import Paginator from "../../../components/paginator/Paginator";

// Formik schema

const ViewProductSKUDetails = (props) => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState("");
  const [existingImages, setExistingImages] = useState([]);
  const [tabvalue, setTabValue] = useState("en");
  const [attributeList, setAttributeList] = useState([]);
  const { enabledLanguages } = useGlobalValues();

  const [searchParams] = useSearchParams();
  const [itemDetail, setItemDetail] = useState([]);
  const [openItemStatusChange, setOpenItemStatusChange] = useState(false);
  const [openItemUpdateChange, setOpenItemUpdateChange] = useState(false);

  const { country } = useContext(CountryContext);

  const [data, setData] = useState();

  let navigate = useNavigate();

  const tableHeaders = [
    { title: "BarCode" },
    { title: "Name" },
    { title: "Location" },
    { title: "Cost" },
    { title: "Date" },
    { title: " Status" },
  ];

  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  // Using useGet to get the items to display, conditions and product usage types for dropdowns
  const {
    data: dataItems,
    loading: loadingItems,
    refetch,
  } = useGet({
    url: `admin/get-product-sku-items`,
    payload: {
      product_sku_id: params.id,
      per_page: 10,
      page: searchParams.get("page") || 1,
    },
  });
  
  let items = dataItems?.data?.data;

  const CheckIfPublishToggle = (array) => {
    let find = array?.find((el) => el?.id === 8 || el?.id === 9);
    return Boolean(find);
  };

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    reValidateMode: "onChange" | "onBlur",
  });

  // API REQUESTS
  // Setting form values when data is fetches then calling the attributes by product id api
  const getData = async () => {
    setLoading(true);
    return await api
      .get(`admin/get-product-sku-by-id/${params.id}`, {
        // Static for now
        params: { country_id: country?.id || 1 },
      })
      .then((res) => {
        console.log(res);
        getProductVariation({ product_id: res?.data?.data?.product?.id }).then(
          (variationResponse) => {
            if (variationResponse?.data?.success) {
              let attributeDatas = [];
              let attributeArray = [...variationResponse?.data?.data];
              attributeArray?.map((element) => {
                let check = res?.data?.data?.product_variation?.find(
                  (val) => val?.attribute_id === element?.id
                );

                attributeDatas.push({
                  attribute_id: element,
                  attribute_value_id: check ? check?.attribute_value : null,
                });
              });

              setData(res?.data?.data);
              setExistingImages(res?.data?.data?.product?.product_image);
              reset({
                sku_code: res?.data?.data?.sku_code,
                product_id: res?.data?.data?.product,
                description: res?.data?.data?.description,
                offered_price: res?.data?.data?.offered_price,
                buyer_price: res?.data?.data?.buyer_price,
                cost: res?.data?.data?.cost,
                name: res?.data?.data?.name,
                brand:
                  res?.data?.data?.product?.brand?.name?.[tabvalue] ||
                  res?.data?.data?.product?.brand?.name?.en,
                category:
                  res?.data?.data?.product?.category?.name?.[tabvalue] ||
                  res?.data?.data?.product?.category?.name?.en,
                condition_id: res?.data?.data?.condition,
                product_usage_type_id: res?.data?.data?.usage_type,
                product_usage_name:
                  res?.data?.data?.usage_type?.name?.[tabvalue],
                warranty: res?.data?.data?.warranty,
                attributes_data: attributeDatas,
                product_variation: res?.data?.data?.product_variation,
              });
              setLoading(false);
            }
          }
        );
      });
  };

  // Calling fetch data api on edit
  useEffect(() => {
    getData();
  }, [tabvalue]);

  const handleChange = (event) => {
    setTabValue(event);
  };

  const fields = [
    { name: "sku_code", label: "Sku", icon: <TurnedInIcon /> },
    { name: "brand", label: "Brand", icon: <AttachFileIcon /> },
    { name: "category", label: "Category", icon: <CategoryIcon /> },
    {
      name: "condition_id.name.en",
      label: "Condition",
      icon: <WorkIcon />,
    },
  ];

  const chunkSize = 2;
  const chunkedFields = [];
  for (let i = 0; i < fields.length; i += chunkSize) {
    chunkedFields.push(fields.slice(i, i + chunkSize));
  }

  return (
    <div className="products-details">
      {!loading ? (
        <Paper elevation={24} className="paper-div">
          <div className="header">
            <TitleComponent
              className="title-details"
              title={getValues("name")}
            />
            <div className="chip-wrapper">
              {enabledLanguages.map((item, index) => (
                <Chip
                  key={index}
                  label={item.name}
                  value={item.code}
                  className={
                    tabvalue != item.code ? "en-chip" : "en-chip color-white"
                  }
                  color="primary"
                  variant={tabvalue != item.code ? "outlined" : ""}
                  onClick={() => handleChange(item.code)}
                />
              ))}
            </div>
          </div>

          <Card variant="elevation">
            <Box sx={{ p: 2 }}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {chunkedFields.map((chunk, index) => (
                  <Grid item xs={12} sm={4} md={4} key={index}>
                    {chunk.map((field) => (
                      <ListItem key={field.name}>
                        <ListItemAvatar>
                          <Avatar className="avatarBg">{field.icon}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={field.label}
                          secondary={getValues(field.name)}
                        />
                      </ListItem>
                    ))}
                  </Grid>
                ))}

                <Grid item xs={4}>
                  <div className="viewModeImages">
                    {existingImages &&
                      existingImages.map((image) => (
                        <div className="image " key={image.id}>
                          <img
                            src={
                              process.env.REACT_APP_IMAGE_URL + image.image_path
                            }
                            style={{ height: "150px", width: "150px" }}
                          />
                        </div>
                      ))}
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Typography gutterBottom variant="h6">
                Attributes
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                sx={{ py: 2 }}
                style={{ maxWidth: "2000px", overflow: "scroll" }}
              >
                {getValues("product_variation")?.map((item) => (
                  <Chip
                    sx={{
                      "& .MuiChip-label": {
                        fontWeight: "bold",
                      },
                    }}
                    variant="outlined"
                    label={
                      (item?.attribute_value?.attribute?.name?.[tabvalue]
                        ? item?.attribute_value?.attribute?.name?.[tabvalue]
                        : item?.attribute_value?.attribute?.name?.en) +
                      ": " +
                      (item?.attribute_value?.name?.[tabvalue]
                        ? item?.attribute_value?.name?.[tabvalue]
                        : item?.attribute_value?.name?.en)
                    }
                  />
                ))}
              </Stack>
            </Box>
            <Divider />

            <Box sx={{ p: 2 }}>
              <p>
                <span
                  style={{
                    color: "#3538CD",
                    borderRadius: "5px",
                    padding: "5px",
                    backgroundColor: "#C7D7FE",
                  }}
                >
                  {items?.total} Items{" "}
                </span>
              </p>
              <TableBodyComponent
                loading={loadingItems}
                header={tableHeaders}
                total={items?.total}
              >
                {items?.data?.length ? (
                  items?.data?.map((element) => (
                    <TableRowComponent
                      internal
                      handleEye={() =>
                        navigate(`/product-sku/edit-item/${element?.id}`, {
                          state: {
                            skuCondition: getValues("condition_id.name.en"),
                          },
                        })
                      }
                      row={element}
                      key={element?.id}
                    >
                      <TableCell style={{ fontWeight: "bold" }}>
                        <Barcode
                          value={element?.item_code}
                          textPosition={"left"}
                          fontSize={10}
                          width={1}
                          height={20}
                          margin={0}
                        />
                      </TableCell>
                      <TableCell>{element?.item_code}</TableCell>{" "}
                      <TableCell>
                        <span
                          style={{
                            backgroundColor: "#C7D7FE",
                            color: "#3538CD",
                            borderRadius: "5px",
                            padding: "5px",
                            display: "flex",
                            justifyContent: "left",
                            width: "min-content",
                            marginBottom: "0px",
                          }}
                        >
                          {element?.country?.name}
                        </span>
                      </TableCell>
                      <TableCell>{element?.item_cost + " " + "AED"}</TableCell>
                      <TableCell>
                        {element?.created_at
                          ? moment(element?.created_at).calendar()
                          : "---"}
                      </TableCell>
                      <TableCell>
                        <Button
                        sx={{
                          minWidth:"100px"
                        }}
                          variant="outlined"
                          color={
                            element?.next_action[0]?.id === 9
                              ? "success"
                              : element?.next_action[0]?.id === 7
                              ? "warning"
                              : "error"
                          }
                          style={{ border: "2px solid" }}
                        >
                          {element?.latest_action?.item_action?.item_status
                            ?.name?.en == "Published"
                            ? "Live"
                            : element?.latest_action?.item_action?.item_status
                                ?.name?.en == "Unpublished"
                            ? "Not Live"
                            : element?.latest_action?.item_action?.item_status
                                ?.name?.en}
                        </Button>
                      </TableCell>
                      <TableCell>
                        {element.next_action.length > 0 && (
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setItemDetail(element);
                              setOpenItemStatusChange(true);
                            }}
                          >
                            {element?.next_action[0]?.name}
                          </Button>
                        )}
                      </TableCell>
                    </TableRowComponent>
                  ))
                ) : (
                  <DataNotFound />
                )}
              </TableBodyComponent>
              {items && <Paginator count={items?.last_page} disabled={loading} />}
            </Box>
          </Card>
          {openItemStatusChange && (
            <ProductStatusHandle
              data={itemDetail}
              refetch={refetch}
              onClose={() => {
                setItemDetail(null);
                setOpenItemStatusChange(false);
              }}
            />
          )}

          {openItemUpdateChange && (
            <ProductItemHandle
              data={itemDetail}
              refetch={refetch}
              onClose={() => {
                setItemDetail(null);
                setOpenItemUpdateChange(false);
              }}
            />
          )}
        </Paper>
      ) : (
        <Paper elevation={24} className="paper-div">
          <Card variant="elevation">
            <Box sx={{ p: 2 }}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {chunkedFields.map((chunk, index) => (
                  <Grid item xs={12} sm={4} md={4} key={index}>
                    {chunk.map((field) => (
                      <ListItem key={field.name}>
                        <ListItemAvatar>
                          <Skeleton variant="circular" width={40} height={40} />
                        </ListItemAvatar>
                        <Skeleton
                          variant="rectangular"
                          width={100}
                          height={20}
                        />
                      </ListItem>
                    ))}
                  </Grid>
                ))}

                <Grid item xs={4}>
                  <div className="viewModeImages">
                    <Skeleton variant="rectangular" width={210} height={150} />
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Typography gutterBottom variant="h6">
                <Skeleton variant="rectangular" width={100} height={20} />
              </Typography>
              <Stack direction="row" spacing={1} sx={{ py: 2 }}>
                <Skeleton variant="rounded" width={150} height={20} />
                <Skeleton variant="rounded" width={150} height={20} />
                <Skeleton variant="rounded" width={150} height={20} />
              </Stack>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
            </Box>
          </Card>
        </Paper>
      )}
    </div>
  );
};

export default ViewProductSKUDetails;
