import React, { useState, useEffect, useContext } from "react";
import { Card, CardContent } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../../components/button/Button";
import {
  FormCheckboxDropdown,
  FormInput,
  FormMultiSelect,
  UploadComponent,
} from "../../../../components/formComponents/FormComponents";
import { useAxios, useGet } from "../../../../hooks/useFetch";
import "./BrandDetails.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import { USAFlagIcon } from "../../../../assests/icons/SVG";
import KSAFlag from "../../../../assests/Images/united-ara.png";
import { translateByGoogleApi } from "../../../../core/apis/newlanguage";
import CustomTab from "../../../../components/Tabs/customTab";
import { updateBrand } from "../../../../core/apis/brands";
import { AlertContext } from "../../../../context/AlertContext";
import { useGlobalValues } from "../../../../context/GlobalContext";
import MetaFieldsForm from "../../../../components/formComponents/dynamicform";

const brandSchema = yup.object({
  name: yup.string("Enter your email").required("Email is required"),
  abbreviation: yup.string("").required("Field is Required"),
  category_ids: yup
    .array()
    .min(1, "Field is required")
    .required("Field is required"),
  metatitle: yup
    .string()
    .required("Meta title is required")
    .max(60, "Meta title must be at most 60 characters"),
  metadescription: yup
    .string()
    .required("Meta description is required")
    .max(160, "Meta description must be at most 160 characters"),
  metakeywords: yup
    .array()
    .min(1, "At least one keyword is required")
    .max(10, "You can add up to 10 keywords only"), // ✅ Limit to 10 items
});

const BrandDetails = (props) => {
  const { create } = props;
  const [tabvalue, setTabValue] = useState("en");
  let navigate = useNavigate();
  const params = useParams();
  const { setAlert } = useContext(AlertContext);
  const { enabledLanguages } = useGlobalValues();

  const {
    data: brand,
    loading,
    refetch,
  } = useGet({ url: `admin/get-brand-by-id/${params.id}`, start: !create });
  const { data: countries, loading: loadingCountries } = useGet({
    url: `get-all-countries`,
  });

  let brands = brand?.data?.data || {};

  const { data: categories, categoriesLoading } = useGet({
    url: "admin/get-all-categories",
  });

  console.log(brands)

  const formik = useFormik({
    initialValues: {
      name: brands?.name?.[tabvalue] || "",
      description: brands?.description?.[tabvalue] || "",
      image: brands?.image_path,
      language: tabvalue,
      country_ids: brands?.country?.map((i) => i?.id) || [],
      abbreviation: brands?.abbreviation || "",
      category_ids: brands?.brand_categories?.map((i) => i?.id) || [],
      metatitle: brands?.meta_title?.[tabvalue] || "",
      metadescription: brands?.meta_description?.[tabvalue] || "",
      metakeywords:brands?.meta_keywords?.[tabvalue]?.split(",")?.map((item, index) => ({
          tag_name: item.trim(),
          id:item.trim(), // Assigning a unique ID (you can modify it as needed)
        })) || [],
    },
    enableReinitialize: true,
    validationSchema: brandSchema,
    onSubmit: () => {
      callAPI();
      refetch();
    },
  });

  const handleCreate = () => {
    let categorIds = [];
    let valueData = new FormData();

    formik?.values?.category_ids.forEach((item) => {
      categorIds.push(item);
    });

    let metaTags = formik?.values?.metakeywords
      .map((tag) => tag.tag_name)
      .join(", ");

    valueData.append("name", formik.values.name);
    valueData.append("abbreviation", formik.values.abbreviation);
    valueData.append("description", formik.values.description);
    valueData.append("image", formik.values.image);
    valueData.append("language", tabvalue);
    valueData.append("category_ids", categorIds);

    valueData.append("meta_title", formik.values.metatitle);
    valueData.append("meta_description", formik.values.metadescription);
    valueData.append("meta_keywords", metaTags);

    formik.values.country_ids.forEach((el, index) => {
      valueData.append(`country_ids[${index}]`, el);
    });

    if (formik.values.image instanceof File === false) {
      valueData.delete("image");
    }

    return valueData;
  };

  const {
    data,
    loading: loadingUpdate,
    callAPI,
  } = useAxios(
    `admin/${create ? "create" : "update"}-brand${
      create ? "" : `/${params.id}`
    }`,
    "post",
    handleCreate()
  );

  const handleTranslateByGoogle = async (values, targetLanguage) => {
    let arrayofdata = [
      values.name.en,
      values.description.en,
      values.meta_description.en,
      values.meta_keywords.en,
      values.meta_title.en,
    ];
    await translateByGoogleApi(arrayofdata, targetLanguage).then((res) => {
      let categorIds = [];
      let valueData = new FormData();

      formik.values.country_ids.forEach((el, index) => {
        valueData.append(`country_ids[${index}]`, el);
      });

      formik?.values?.category_ids.forEach((item) => {
        categorIds.push(item);
      });

      valueData.append("name", res[0]?.translatedText);
      valueData.append("abbreviation", formik.values.abbreviation);
      valueData.append("description", res[1]?.translatedText);
      valueData.append("language", targetLanguage);
      valueData.append("category_ids", categorIds);

      valueData.append("meta_title","");
      valueData.append("meta_description", "");
      valueData.append("meta_keywords", "");

      if (valueData) {
        updateBrand(valueData, values.id).then((res) => {
          setAlert({
            visible: true,
            text: res.data ? res.data.message : res.message,
            type: res.data?.success ? "success" : "error",
          });
          navigate("/brands");
        });
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (create && data?.data?.success) {
        // Call your additional function here
        enabledLanguages.map((item) => {
          if (item.code != "en") {
            handleTranslateByGoogle(data.data.data, item.code);
          }
        });
      }
    };

    fetchData();
  }, [create, data]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  if (!create && data?.data?.success) {
    navigate(-1);
  }

  return (!create && loading) || loadingCountries ? (
    "loading..."
  ) : (
    <>
      <h1 className={tabvalue != "ar" ? "" : " arabic-component"}>
        {create ? "New Brand" : brands?.name?.[tabvalue] || ""}
      </h1>

      <TabContext value={tabvalue}>
        <Tabs
          value={tabvalue}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          {enabledLanguages.map((item, index) =>
            create && item.code === "en" ? (
              <CustomTab
                index={index}
                value={item.code}
                label={item.name}
                icon={<USAFlagIcon />}
              />
            ) : (
              !create && (
                <CustomTab
                  index={index}
                  value={item.code}
                  label={item.name}
                  icon={
                    item.code == "en" ? (
                      <USAFlagIcon />
                    ) : item.code == "ar" ? (
                      <img src={KSAFlag} width="30" />
                    ) : (
                      ""
                    )
                  }
                />
              )
            )
          )}
        </Tabs>

        <form
          onSubmit={formik.handleSubmit}
          className={
            tabvalue != "ar" ? "BrandDetails" : " BrandDetails arabic-component"
          }
        >
          <div className="form-section">
            <Card>
              <CardContent>
                <FormInput
                  label="Brand"
                  placeholder="Enter Brand Name"
                  name="name"
                  id="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
                {tabvalue != "ar" && (
                  <FormInput
                    label="Abbreviation"
                    placeholder="Enter Abbreviation"
                    name="abbreviation"
                    id="abbreviation"
                    value={formik.values.abbreviation}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.abbreviation &&
                      Boolean(formik.errors.abbreviation)
                    }
                    helperText={
                      formik.touched.abbreviation && formik.errors.abbreviation
                    }
                  />
                )}

                {tabvalue != "ar" && (
                  <FormMultiSelect
                    required
                    data={categories?.data?.data?.categories || []}
                    loading={categoriesLoading}
                    label="Categories"
                    name="category_ids"
                    dependencyError={["category_ids"]}
                    placeholder={"Select Categories"}
                    value={categories?.data?.data?.categories?.filter((i) =>
                      formik?.values?.category_ids.includes(+i?.id)
                    )}
                    onChange={(value) => {
                      formik.setFieldValue(
                        "category_ids",
                        value.map((i) => i.id) ||
                          formik.initialValues.category_ids
                      );
                    }}
                    helperText={
                      formik.touched.abbreviation && formik.errors.abbreviation
                    }
                  />
                )}

                <FormInput
                  label="Description"
                  placeholder="Enter Description"
                  name="description"
                  id="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
                {tabvalue != "ar" && (
                  <FormCheckboxDropdown
                    label="Countries"
                    name="country_ids"
                    id="country_ids"
                    multiple
                    loading={loadingCountries}
                    options={countries?.data?.data || []}
                    value={countries?.data?.data.filter((i) =>
                      formik?.values?.country_ids.includes(+i?.id)
                    )}
                    onChange={(_, value) => {
                      formik.setFieldValue(
                        "country_ids",
                        value.find((option) => option.value === "select-all")
                          ? countries?.data?.data.map((i) => i.id)
                          : value.map((i) => i.id) ||
                              formik.initialValues.country_ids
                      );
                    }}
                    placeholder={tabvalue != "ar" ? "Choose an Option" : ""}
                  />
                )}

                <div className="my-4">
                  <h6>Upload Image</h6>
                  {tabvalue != "ar" && (
                    <UploadComponent
                      isArabic={tabvalue == "ar" ? true : false}
                      value={formik.values.image}
                      setFieldValue={formik.setFieldValue}
                    />
                  )}
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="form-section">
            <MetaFieldsForm formik={formik} />

            <div className="action-footer">
              <Button
                name={create ? "Create" : "Save"}
                selected
                loading={loadingUpdate}
                disabled={!formik.dirty || (!create && loading)}
                type="submit"
                width={"40%"}
              />
            </div>
          </div>
        </form>
      </TabContext>
    </>
  );
};

export default BrandDetails;
