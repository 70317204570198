// EXTERNAL LIBRARIES
import React, { useState, useEffect, useContext } from "react";
import { Card, Paper, Tab } from "@mui/material";

import { useForm, Controller } from "react-hook-form";
// STYLING
import "../../brand-product-type/products/productDetails/ProductDetails.scss";
import "../../productSKU/editproductskudetails/editproductskudetails.scss";

// CUSTOM HOOKS
import { api } from "../../../core/apis/main";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  FormInput,
  FormDropdownList,
} from "../../../components/formComponents/FormComponents";
import { CountryContext } from "../../../context/CountryContext";
import { useGlobalValues } from "../../../context/GlobalContext";
import TitleComponent from "../../../components/titleComponent/TitleComponent";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import WorkIcon from "@mui/icons-material/Work";
import CategoryIcon from "@mui/icons-material/Category";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Cropper from "../../../components/singleUpload/imageCropper";
import { useParams } from "react-router-dom";
import { getProductVariation } from "../../../core/apis/product";
import { AlertContext } from "../../../context/AlertContext";
import {
  delteProductSkuImages,
  uploadProductSkuFeaturedItemImage,
  uploadProductSkuImages,
  updateProductSku,
  getproductskuImages,
} from "../../../core/apis/item";
import NewEditor from "../../../components/editor/NewEditor";
import Button from "../../../components/button/Button";
import MultipleCropper from "../../../components/singleUpload/multipleImageCropper";
import { LiveStatues } from "../../../core/constants/constant";
import MetaFieldsForm from "../../../components/formComponents/metadynamicform";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import WidgetsIcon from "@mui/icons-material/Widgets";
import StoreIcon from "@mui/icons-material/Store";

// Formik schema

const HandleSchema = yup.object({
  metatitle: yup
    .string()
    .required("Meta title is required")
    .max(60, "Meta title must be at most 60 characters"),
  metadescription: yup
    .string()
    .required("Meta description is required")
    .max(160, "Meta description must be at most 160 characters"),
  metakeywords: yup
    .array()
    .min(1, "At least one keyword is required")
    .max(10, "You can add up to 10 keywords only"), // ✅ Limit to 10 items
});

const EditProductSKUDetails = (props) => {
  const params = useParams();
  const { setAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [existingImages, setExistingImages] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [tabvalue, setTabValue] = useState("en");
  const { enabledLanguages } = useGlobalValues();
  const [uploadFeatured, setuploadFeaturedImage] = useState(false);
  const [imageVal, setImageValue] = useState(null);
  const { country } = useContext(CountryContext);
  const [data, setData] = useState();
  const [deletedImages, setDeletedImages] = useState([]);
  const [tabdetailsvalue, setTabDetailsValue] = useState("0");

  const [existedFeaturedImage, setexistedFeaturedImage] = useState();

  const fields = [
    { name: "sku_code", label: "Sku", icon: <TurnedInIcon /> },
    { name: "brand", label: "Brand", icon: <AttachFileIcon /> },
    { name: "category", label: "Category", icon: <CategoryIcon /> },
    {
      name: "condition_id.name.en",
      label: "Condition",
      icon: <WorkIcon />,
    },
  ];

  const chunkSize = 2;
  const chunkedFields = [];
  for (let i = 0; i < fields.length; i += chunkSize) {
    chunkedFields.push(fields.slice(i, i + chunkSize));
  }

  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(HandleSchema) });

  // API REQUESTS
  // Setting form values when data is fetches then calling the attributes by product id api
  const getData = async () => {
    setLoading(true);
    return await api
      .get(`admin/get-product-sku-by-id/${params.id}`, {
        // Static for now
        params: { country_id: country?.id || 1 },
      })
      .then((res) => {
        getProductVariation({ product_id: res?.data?.data?.product?.id }).then(
          (variationResponse) => {
            if (variationResponse?.data?.success) {
              let attributeDatas = [];
              let attributeArray = [...variationResponse?.data?.data];
              attributeArray?.map((element) => {
                let check = res?.data?.data?.product_variation?.find(
                  (val) => val?.attribute_id === element?.id
                );

                attributeDatas.push({
                  attribute_id: element,
                  attribute_value_id: check ? check?.attribute_value : null,
                });
              });

              setData(res?.data?.data);
              reset({
                sku_code: res?.data?.data?.sku_code,
                product_id: res?.data?.data?.product,
                description:
                  res?.data?.data?.description?.[tabvalue] ||
                  res?.data?.data?.description?.en,
                sell_price: res?.data?.data?.sell_price,
                name: res?.data?.data?.name,
                title:
                  res?.data?.data?.title?.[tabvalue] ||
                  res?.data?.data?.title?.en,
                brand:
                  res?.data?.data?.product?.brand?.name?.[tabvalue] ||
                  res?.data?.data?.product?.brand?.name?.en,
                category:
                  res?.data?.data?.product?.category?.name?.[tabvalue] ||
                  res?.data?.data?.product?.category?.name?.en,
                condition_id: res?.data?.data?.condition,
                attributes_data: attributeDatas,
                active: res?.data?.data?.active
                  ? LiveStatues[0]
                  : LiveStatues[1],
                qr_link: res?.data?.data?.qr_link,
                product_variation: res?.data?.data?.product_variation,
                metatitle: res?.data?.data.meta_title?.[tabvalue],
                metadescription: res?.data?.data.meta_description?.[tabvalue],
                metakeywords: res?.data?.data?.meta_keywords?.[tabvalue]?.split(",")?.map((item, index) => ({
                      tag_name: item.trim(),
                      id: item.trim(), // Assigning a unique ID (you can modify it as needed)
                    }))
                  || []
              });
              setLoading(false);
            }
          }
        );
      });
  };

  const getproductImages = () => {
    getproductskuImages(params.id).then((res) => {
      setExistingImages(res?.data?.data);
      let featured = res.data.data.filter((item) => item.featured);
      setexistedFeaturedImage(featured[0]);
    });
  };

  // Calling fetch data api on edit
  useEffect(() => {
    getData();
    getproductImages();
  }, [tabvalue]);

  const handleChange = (event) => {
    setTabValue(event);
  };

  const handleImage = (croppedImage) => {
    setImageValue(croppedImage);
    setValue("featured_image", croppedImage);
    if (existedFeaturedImage != undefined) {
      handleDeleteImage(existedFeaturedImage?.id);
    }
    handleuploadFeaturedImage(croppedImage);
  };

  const handleuploadFeaturedImage = (croppedImage) => {
    setuploadFeaturedImage(true);
    let formData = new FormData();
    formData.append("product_sku_id", params.id);
    formData.append("image", croppedImage);
    uploadProductSkuFeaturedItemImage(formData).then((res) => {
      setAlert({
        visible: true,
        text: res.data.message,
        type: res.data.success ? "success" : "error",
      });
      setuploadFeaturedImage(false);
    });
  };

  // HandleImages
  const handleImages = (imagesList) => {
    let existing = fileList ? [...fileList] : [];
    existing.push(imagesList);
    setFileList(existing);
    setValue("imageList", existing);
  };

  const handleuploadImages = () => {
    let formData = new FormData();
    formData.append("product_sku_id", params.id);
    fileList.forEach((image, index) =>
      formData.append(`images[${index}]`, image)
    );
    uploadProductSkuImages(formData).then((res) => {
      setAlert({
        visible: true,
        text: res.data.message,
        type: res.data.success ? "success" : "error",
      });
      setFileList([]);
    });
  };

  //delete selected images
  const handleDeleteImage = (images) => {
    let payload = {
      image_id: images,
    };
    delteProductSkuImages(params.id, payload).then((res) => {
      setAlert({
        visible: true,
        text: res.data.message,
        type: res.data.success ? "success" : "error",
      });
    });
  };

  const handleSubmitForm = (values) => {
    console.log(values);

    let metaTags = values.metakeywords.map((tag) => tag.tag_name).join(", ");

    let payload = {
      id: params?.id,
      title: values?.title,
      description: values?.description,
      sell_price: values?.sell_price,
      qr_link: values?.qr_link,
      active: values.active.value,
      meta_title: values.metatitle,
      meta_description: values.metadescription,
      meta_keywords: metaTags,
      language: tabvalue,
    };

    if (fileList?.length > 0) {
      handleuploadImages();
    }
    updateProductSku(params.id, payload).then((res) => {
      if (res.data.success) {
        getData();
        getproductImages();
      }
      setAlert({
        visible: true,
        text: res.data.message,
        type: res.data.success ? "success" : "error",
      });
    });
  };

  // Removing file from list state (currently selected by user)
  const removeFile = (file) => {
    let filtered = fileList.filter(function (e) {
      return e != file;
    });
    setFileList(filtered);
    setValue("imageList", filtered);
  };

  // Setting the files to be deleted from the existing files (fetched data for edit)
  const removeImage = (id) => {
    deletedImages.push(id);
    setDeletedImages(deletedImages);
    let filtered = existingImages.filter(function (e) {
      return e.id != id;
    });
    setExistingImages(filtered);
  };

  const handleTabChange = (event, value) => {
    console.log(value);
    setTabDetailsValue(value);
  };

  return (
    <div className="products-details">
      {!loading ? (
        <Paper elevation={24} className="paper-div">
          <div className="header">
            <TitleComponent
              className="title-details"
              title={getValues("name")}
            />
            <div className="chip-wrapper">
              {enabledLanguages.map((item, index) => (
                <Chip
                  key={index}
                  label={item.name}
                  value={item.code}
                  className={
                    tabvalue != item.code ? "en-chip" : "en-chip color-white"
                  }
                  color="primary"
                  variant={tabvalue != item.code ? "outlined" : ""}
                  onClick={() => handleChange(item.code)}
                />
              ))}
            </div>
          </div>

          <Card variant="elevation">
            <Box sx={{ p: 2 }}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {chunkedFields.map((chunk, index) => (
                  <Grid item xs={12} sm={4} md={4} key={index}>
                    {chunk.map((field) => (
                      <ListItem key={field.name}>
                        <ListItemAvatar>
                          <Avatar className="avatarBg">{field.icon}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={field.label}
                          secondary={getValues(field.name)}
                        />
                      </ListItem>
                    ))}
                  </Grid>
                ))}

                <Grid item xs={6} sm={4}>
                  {tabvalue == "en" && (
                    <Cropper
                      sendCroppedFile={handleImage}
                      imageSrc={
                        existedFeaturedImage?.image_path
                          ? existedFeaturedImage?.image_path
                          : null
                      }
                      MAX_IMAGE_WIDTH={500}
                      MAX_IMAGE_HEIGHT={500}
                      title={"Upload Featured Image 500*500"}
                      imgClassName={"imgwidth200"}
                      isTrue={false}
                      isloading={uploadFeatured}
                      iwebp={true}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <TabContext value={tabdetailsvalue}>
              <TabList
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                <Tab
                  label={"Details"}
                  value={"0"}
                  icon={<WidgetsIcon />}
                  iconPosition="start"
                />
                <Tab
                  label={"Meta Details"}
                  value={"1"}
                  icon={<StoreIcon />}
                  iconPosition="start"
                />
              </TabList>

              <div className="editskuDetails">
                <form onSubmit={handleSubmit(handleSubmitForm)}>
                  <TabPanel value="0">
                    <div
                      className={
                        tabvalue == "ar" ? "form-section " : "form-section"
                      }
                    >
                      <div className="itemFlexRow">
                        <div className="flex60COL">
                          {tabvalue == "en" && (
                            <Controller
                              name="active"
                              control={control}
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <FormDropdownList
                                  data={LiveStatues || []}
                                  label="Product Status"
                                  name="active"
                                  placeholder={"Select Product Status"}
                                  value={value || ""}
                                  hideVariant
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          )}
                          <Controller
                            render={({ field, fieldState: { error } }) => (
                              <FormInput
                                className="formInput1"
                                label={
                                  tabvalue == "en"
                                    ? "Custom Title"
                                    : "Arabic Custom Title"
                                }
                                name="title"
                                hideVariant={true}
                                type="text"
                                placeholder="Enter Title"
                                value={field.value}
                                onChange={(e) => field.onChange(e.target.value)}
                                helperText={error?.message}
                              />
                            )}
                            name="title"
                            control={control}
                          />
                          {tabvalue == "en" && (
                            <>
                              <Controller
                                render={({ field, fieldState: { error } }) => (
                                  <FormInput
                                    className="formInput1"
                                    label="Sell Price"
                                    name="sell_price"
                                    hideVariant={true}
                                    type="number"
                                    placeholder="Enter Sell price"
                                    value={field.value}
                                    onChange={(e) =>
                                      field.onChange(e.target.value)
                                    }
                                    helperText={error?.message}
                                  />
                                )}
                                name="sell_price"
                                control={control}
                              />

                              <Controller
                                render={({ field, fieldState: { error } }) => (
                                  <FormInput
                                    className="formInput1"
                                    label="QR LINK"
                                    name="qr_link"
                                    hideVariant={true}
                                    placeholder="Enter QR link"
                                    value={field.value}
                                    onChange={(e) =>
                                      field.onChange(e.target.value)
                                    }
                                    helperText={error?.message}
                                  />
                                )}
                                name="qr_link"
                                control={control}
                              />
                            </>
                          )}

                          <Controller
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <NewEditor
                                label={
                                  tabvalue == "en"
                                    ? "Description"
                                    : "Arabic Description"
                                }
                                value={value}
                                lang={tabvalue}
                                onChange={(e) => onChange(e)}
                              />
                            )}
                            name="description"
                            control={control}
                          />
                        </div>
                        {tabvalue == "en" && (
                          <div className="flex40COL">
                            <div className="upload-body">
                              <div className="filesSection">
                                <Controller
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => (
                                    <MultipleCropper
                                      sendCroppedFile={handleImages}
                                      MAX_IMAGE_WIDTH={500}
                                      MAX_IMAGE_HEIGHT={500}
                                      sizeMsg={"500*500"}
                                      message={error?.message}
                                      error={error}
                                      className={"margin0"}
                                      iwebp={true}
                                    />
                                  )}
                                  name="imageList"
                                  control={control}
                                />
                                <div className="files">
                                  <ul className="fileList">
                                    {existingImages &&
                                      existingImages
                                        .filter((item) => !item.featured)
                                        .map((image) => (
                                          <li className="image" key={image.id}>
                                            <img
                                              src={
                                                process.env
                                                  .REACT_APP_IMAGE_URL +
                                                image.image_path
                                              }
                                            />
                                            <button
                                              type="button"
                                              onClick={(e) => {
                                                removeImage(image.id);
                                                handleDeleteImage(image.id);
                                                e.preventDefault();
                                              }}
                                            >
                                              x
                                            </button>
                                          </li>
                                        ))}
                                  </ul>
                                  <ul className="fileList">
                                    {fileList &&
                                      fileList.map((file, i) => (
                                        <li className="image" key={i}>
                                          <img
                                            src={URL.createObjectURL(file)}
                                          />
                                          <button
                                            type="button"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              removeFile(file);
                                            }}
                                          >
                                            x
                                          </button>
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value="1">
                    <MetaFieldsForm
                      control={control}
                      setValue={setValue}
                      getValues={getValues}
                      clearErrors={clearErrors}
                    />
                  </TabPanel>
                  <div className="action-footer my-2 mx-2">
                    <Button type="button" name="Cancel" />
                    <Button className="save-button" type="submit" name="Save" />
                  </div>
                </form>
              </div>
            </TabContext>
          </Card>
        </Paper>
      ) : (
        <Paper elevation={24} className="paper-div">
          <Card variant="elevation">
            <Box sx={{ p: 2 }}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {chunkedFields.map((chunk, index) => (
                  <Grid item xs={12} sm={4} md={4} key={index}>
                    {chunk.map((field) => (
                      <ListItem key={field.name}>
                        <ListItemAvatar>
                          <Skeleton variant="circular" width={40} height={40} />
                        </ListItemAvatar>
                        <Skeleton
                          variant="rectangular"
                          width={100}
                          height={20}
                        />
                      </ListItem>
                    ))}
                  </Grid>
                ))}

                <Grid item xs={4}>
                  <div className="viewModeImages">
                    <Skeleton variant="rectangular" width={210} height={150} />
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Typography gutterBottom variant="h6">
                <Skeleton variant="rectangular" width={100} height={20} />
              </Typography>
              <Stack direction="row" spacing={1} sx={{ py: 2 }}>
                <Skeleton variant="rounded" width={150} height={20} />
                <Skeleton variant="rounded" width={150} height={20} />
                <Skeleton variant="rounded" width={150} height={20} />
              </Stack>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
            </Box>
          </Card>
        </Paper>
      )}
    </div>
  );
};

export default EditProductSKUDetails;
